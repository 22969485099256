import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import PopularLocationsModal from "./PopularLocationModal";
import { GET_POPULAR_LOCATION, GET_POPULAR_LOCATIONS } from "src/graphql/query";
import { DELETE_POPULAR_LOCATIONS } from "src/graphql/mutations";
import DeleteModel from "src/components/Common/DeleteModel";
import {
  PopularLocationCategory,
  popularLocationCategoryOptions,
} from "./popularLocationCategory";
import Select from "react-select";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PopularLocationDetails from "./PopularLocationDetails";
interface Popularlocations {
  _id: string;
  title: string;
  description: string;
  address: string;
  pincode: string;
  googlelocationlink: number;
  category: string;
}

const Popularlocations: React.FC = () => {
  const [list, setList] = useState<Popularlocations[]>([]);
  const [selectedPopularlocations, setSelectedPopularlocations] =
    useState<Popularlocations | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deletePopularlocationId, setDeletePopularlocationId] = useState<
    string | null
  >(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);
  const [deletePopularlocations] = useMutation(DELETE_POPULAR_LOCATIONS);
  const [filterOption, setFilterOption] = useState<
    PopularLocationCategory | "All"
  >("All");

  const COLUMNS = [
    { headerName: "Title", field: "title", flex: 1 },
    { headerName: "Description", field: "description", flex: 1 },
    { headerName: "Address", field: "address", flex: 1 },
    {
      headerName: "Google Location Link",
      field: "googlelocationlink",
      flex: 1,
    },
    { headerName: "Category", field: "category", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: Popularlocations }) => (
        <div>
          <IconButton
            onClick={() => handleView(row)}
            aria-label="view"
            style={{ color: "#00C5B9" }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEdit(row)}
              aria-label="edit"
              style={{ marginRight: "8px", color: "#00C5B9" }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setDeletePopularlocationId(row._id);
                setOpenDeleteModel(true);
              }}
              aria-label="delete"
              style={{ color: "#00C5B9" }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_POPULAR_LOCATIONS, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
      category: filterOption,
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page,
        limit: pagination.pageSize,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize]
  );

  const handleView = async (rowData: any) => {
    try {
      const { data } = await getPopularLocation({
        variables: {
          id: rowData._id,
        },
      });
      setSelectedPopularlocations(data?.getPopularLocationById?.data);
      setOpenViewModal(true);
    } catch (error) {
      console.error("Error fetching popular location:", error);
    }
  };
  useEffect(() => {
    if (data) {
      setList(data.getPopularLocations.data);
      setTotal(data.getPopularLocations.count);
    }
  }, [data]);

  const [getPopularLocation] = useLazyQuery(GET_POPULAR_LOCATION);

  const handleEdit = async (rowData: Popularlocations) => {
    try {
      const { data } = await getPopularLocation({
        variables: {
          id: rowData._id,
        },
      });
      setSelectedPopularlocations(data?.getPopularLocationById?.data);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching popular location:", error);
    }
  };

  const handleDelete = async () => {
    if (!deletePopularlocationId) return;
    try {
      await deletePopularlocations({
        variables: { id: deletePopularlocationId },
      });
      refetch();
      setOpenDeleteModel(false);
    } catch (error) {
      console.error("Error deleting popular location:", error);
    }
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeletePopularlocationId(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedPopularlocations(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  const handleFilterChange = (selectedOption: any) => {
    const value = selectedOption ? selectedOption.value : "All";
    setFilterOption(value as PopularLocationCategory | "All");
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: pagination.pageSize,
      category: value === "All" ? undefined : value,
    });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Popular Location
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box width={200}>
            <Select
              value={popularLocationCategoryOptions.find(
                (option) => option.value === filterOption
              )}
              onChange={handleFilterChange}
              options={popularLocationCategoryOptions}
              isClearable
              placeholder="Select Filter"
            />
          </Box>
          <Box width={100}>
            <CustomButton
              onClick={() => setOpenModal(true)}
              variant="contained"
              className="width: 200px"
            >
              Create
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>

      {openModal && (
        <PopularLocationsModal
          open={openModal}
          handleClose={handleCloseModal}
          popularlocations={selectedPopularlocations}
          refetchPopularlocations={refetch}
        />
      )}

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this popular location?"
        />
      )}
      {openViewModal && (
        <PopularLocationDetails
          locationData={selectedPopularlocations}
          onClose={() => setOpenViewModal(false)}
          open={openViewModal}
        />
      )}
    </Box>
  );
};

export default Popularlocations;
