import React, { useEffect, useState } from "react";
import Select from "react-select";
import { IconButton, Box, Typography, FormControl } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_TOP_BUSINESS,
  DELETE_TOP_BUSINESS,
  UPDATE_TOP_BUSINESS,
} from "src/graphql/mutations";
import { useParams } from "react-router";
import {
  GET_BUSINESS_DATA,
  GET_PINCODES,
  GET_SUB_CATEGORY_DATA,
} from "src/graphql/query";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import CustomTable from "src/components/CustomTable";
import CustomButton from "src/components/CustomButton";
import TopThreeBusinessModal from "./TopThreeBusinessModal";
import { TopBusinessProps, Business, BusinessType } from "./SubCategory";
import PincodeSelectDropDown from "src/components/Common/PincodeSelectDropDown";
import DeleteModel from "src/components/Common/DeleteModel";

const TopThreeBusinessTable: React.FC<TopBusinessProps> = ({ reload }) => {
  const [topThreeBusiness, setTopThreeBusiness] = useState<Business[]>([]);
  const [newBusiness, setNewBusiness] = useState<any>({
    businessProfileId: "",
    subCategoryId: "",
    rank: 0,
    title: "",
    description: "",
    pinCodeId: null,
    startDate: null,
    endDate: null,
  });
  const [editBusinessId, setEditBusinessId] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedPincode, setSelectedPincode] = useState<any>(null);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteBusinessId, setDeleteBusinessId] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();

  // GraphQL Mutations
  const [AddTopBusiness] = useMutation(ADD_TOP_BUSINESS);
  const [DeleteTopBusiness] = useMutation(DELETE_TOP_BUSINESS);
  const [UpdateTopBusiness] = useMutation(UPDATE_TOP_BUSINESS);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });

  const usedRanks = topThreeBusiness.map((item: Business) => item.rank) || [];

  // Fetch businesses based on selected pincode and subcategory
  const {
    data: subCategoryData,
    loading: subCategoryLoading,
    error: subCategoryError,
    refetch,
  } = useQuery(GET_SUB_CATEGORY_DATA, {
    variables: {
      subcategoryId: id,
      pincode: selectedPincode,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (subCategoryData) {
      setTopThreeBusiness(
        subCategoryData?.getRelatedDataBySubCategory?.topThreeBusiness || []
      );
    }
  }, [subCategoryData]);

  // Fetch available business profiles
  const {
    data: businessData,
    loading: businessLoading,
    error: businessError,
  } = useQuery(GET_BUSINESS_DATA, {
    variables: {
      primaryCategory: id,
      pinCodeId: selectedPincode ? selectedPincode.value : null,
    },
  });

  const businessProfiles = businessData?.getBusinessProfiles?.data || [];

  // Fetch available pincodes
  const {
    loading: pincodeLoading,
    error: pincodeError,
    data: pincodeData,
  } = useQuery(GET_PINCODES);

  const pincodes = pincodeData?.getPinCodes?.data || [];

  // Handle pincode change
  const handlePincodeChange = (selectedOption: any) => {
    setSelectedPincode(selectedOption?.value);
    refetch();
    reload();
  };

  const pincodeOptions = pincodes.map((pincode: any) => ({
    value: pincode._id,
    label: pincode.areaName,
  }));

  const handleAddBusiness = async (values: any) => {
    try {
      const variables = {
        input: {
          type: BusinessType.TopThree,
          businessProfileId: values.businessProfileId,
          subCategoryId: id,
          rank: `${values.rank}`,
          title: values.title,
          pinCodeId: values?.pinCodeId,
          description: values?.description,
          startDate: values?.startDate,
          endDate: values?.endDate,
        },
      };

      const res = editBusinessId
        ? await UpdateTopBusiness({
            variables: {
              _id: editBusinessId,
              input: variables.input,
            },
          })
        : await AddTopBusiness({ variables });

      if (res.errors) {
        throw new Error("Internal Error");
      }

      toast.success(
        editBusinessId
          ? "Business updated successfully!"
          : "Business added successfully!"
      );
      reload();
      handleCloseModal();
    } catch (error: any) {
      toast.error(error?.message || "Internal Error");
    }
  };

  const handleDeleteBusiness = async () => {
    if (!deleteBusinessId) return;
    try {
      await DeleteTopBusiness({ variables: { _id: deleteBusinessId } });
      toast.success("Business deleted successfully!");
      refetch();
      setOpenDeleteModel(false);
    } catch (error: any) {
      toast.error(error.message || "Internal Error");
    }
  };

  const handleEditBusiness = (id: string) => {
    const businessToEdit = topThreeBusiness.find(
      (business) => business._id === id
    );
    if (businessToEdit) {
      setEditBusinessId(businessToEdit._id);
      setNewBusiness({
        businessProfileId: businessToEdit.businessProfileId,
        subCategoryId: businessToEdit.subCategoryId,
        rank: businessToEdit.rank,
        title: businessToEdit.title,
        description: businessToEdit.description,
        pinCodeId: businessToEdit.pinCodeId,
        startDate: businessToEdit.startDate,
        endDate: businessToEdit.endDate,
      });
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setNewBusiness({
      businessProfileId: "",
      subCategoryId: "",
      rank: 0,
      title: "",
      description: "",
      pinCodeId: null,
    });
    setEditBusinessId(null);
    setOpenModal(false);
  };

  if (businessLoading || subCategoryLoading || pincodeLoading)
    return <Loader />;
  if (businessError || subCategoryError || pincodeError)
    return <ErrorComponent />;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <FormControl style={{ width: "200px" }}>
          <PincodeSelectDropDown
            selectedPincode={selectedPincode}
            handlePincodeChange={handlePincodeChange}
          />
        </FormControl>
        <Box width={200} mx={4}>
          <CustomButton onClick={() => setOpenModal(true)}>Create</CustomButton>
        </Box>
      </Box>

      <CustomTable
        columns={[
          { headerName: "Rank", field: "rank", flex: 1 },
          { headerName: "Title", field: "title", flex: 1 },
          { headerName: "Description", field: "description", flex: 1 },
          {
            headerName: "Actions",
            flex: 1,
            renderCell: ({ row }: { row: { _id: string } }) => (
              <Box>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditBusiness(row._id);
                  }}
                  aria-label="edit"
                  style={{ marginRight: "8px", color: "#00C5B9" }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  style={{ color: "#00C5B9" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteBusinessId(row._id);
                    setOpenDeleteModel(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ),
          },
        ]}
        data={topThreeBusiness}
        onPageChange={() => {}}
        paginationModel={{
          page: pagination.page - 1,
          pageSize: pagination.pageSize,
        }}
        onPageSizeChange={() => {}}
        totalCount={topThreeBusiness?.length}
      />

      {openModal && (
        <TopThreeBusinessModal
          open={openModal}
          handleClose={handleCloseModal}
          handleAddBusiness={handleAddBusiness}
          newBusiness={newBusiness}
          setNewBusiness={setNewBusiness}
          pincodeOptions={pincodeOptions}
          businessProfiles={businessProfiles}
          usedRanks={usedRanks}
        />
      )}

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDeleteBusiness}
          message="Are you sure you want to delete this business?"
        />
      )}
    </div>
  );
};

export default TopThreeBusinessTable;
