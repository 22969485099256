import React from "react";
import {
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";

// Function to convert 24-hour format to 12-hour format with AM/PM
const convertTo12HourFormat = (time: string): string => {
  const [hour, minute] = time.split(":").map(Number);
  const period = hour >= 12 ? "PM" : "AM";
  const adjustedHour = hour % 12 || 12; // 12-hour format adjustment
  return `${adjustedHour}:${minute.toString().padStart(2, "0")} ${period}`;
};

// Generate time options dynamically with an additional "24 Hours Open" option
const generateTimeOptions = () => {
  const options = [];

  for (let i = 0; i < 24; i++) {
    const hourString = i.toString().padStart(2, "0") + ":00";
    const formattedTime = convertTo12HourFormat(hourString);
    options.push({ value: formattedTime, label: formattedTime });
  }

  options.push({ value: "24Hours", label: "24 Hours Open" });
  return options;
};

const timesOptions = generateTimeOptions();

const daysOptions = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" },
];

interface Timing {
  day: string;
  startTime: string;
  endTime: string;
  other?: string;
}

interface TimingSelectProps {
  timings: Timing[];
  onTimingChange: (index: number, field: keyof Timing, value: string) => void;
  onRemove: (index: number) => void;
  availableDays: string[];
  index: number;
  otherShown?: boolean;
}

const TimingSelect: React.FC<TimingSelectProps> = ({
  timings,
  onTimingChange,
  onRemove,
  availableDays,
  index,
  otherShown = true,
}) => {
  const handleTimeChange = (
    index: number,
    field: keyof Timing,
    selectedOption: { value: string; label: string } | null
  ) => {
    if (selectedOption?.value === "24Hours") {
      onTimingChange(index, "startTime", "12:00 AM");
      onTimingChange(index, "endTime", "11:59 PM");
    } else {
      const convertedValue = selectedOption ? selectedOption.value : "";
      onTimingChange(index, field, convertedValue);
    }
  };

  return (
    <Grid container spacing={2} key={index} sx={{ display: "flex" }}>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth sx={{ zIndex: `${9999 - index}` }}>
          <Select
            value={daysOptions.find(
              (option) =>
                option.value.toLowerCase() === timings[index].day.toLowerCase()
            )}
            options={daysOptions.filter(
              (option) =>
                !availableDays.some(
                  (day) => day.toLowerCase() === option.value.toLowerCase()
                ) || option.value === timings[index].day
            )}
            onChange={(selectedOption) =>
              onTimingChange(index, "day", selectedOption?.value ?? "")
            }
            placeholder="Select day"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth sx={{ zIndex: `${9999 - index}` }}>
          <Select
            value={timesOptions.find(
              (option) => timings[index].startTime === option.label
            )}
            options={timesOptions}
            onChange={(selectedOption) =>
              handleTimeChange(index, "startTime", selectedOption)
            }
            placeholder="Select start time"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth sx={{ zIndex: `${9999 - index}` }}>
          <Select
            value={timesOptions.find(
              (option) => timings[index].endTime === option.label
            )}
            options={timesOptions}
            onChange={(selectedOption) =>
              handleTimeChange(index, "endTime", selectedOption)
            }
            placeholder="Select end time"
          />
        </FormControl>
      </Grid>
      {otherShown && (
        <Grid item xs={12} sm={3}>
          <TextField
            label="Other"
            name="other"
            value={timings[index].other}
            onChange={(e) => onTimingChange(index, "other", e.target.value)}
            fullWidth
            variant="outlined"
          />
        </Grid>
      )}
      <Grid item xs={12} sm={2}>
        <IconButton aria-label="delete" onClick={() => onRemove(index)}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default TimingSelect;
