import React, { useState, useCallback, useEffect } from "react";
import { Box, TextField, Typography, Divider } from "@mui/material";
import { useQuery } from "@apollo/client";
import PincodeModal from "./PincodeModal";
import { GET_PINCODES } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import { useDispatch } from "react-redux";
import { ACTION_DELETE_PINCODE } from "src/store/Action/PincodeActions/DeletePincodeAction";
import { toast } from "react-toastify";
import DeleteModel from "src/components/Common/DeleteModel";

interface Pincode {
  _id: string;
  areaName: string;
  pinCode: string;
}

const Pincode: React.FC = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState<Pincode[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [selectedPincode, setSelectedPincode] = useState<Pincode | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);
  const [isEditPincode, setIsEditPincode] = useState(false);
  const initialPinCodeObj = {
    areaName: "",
    pinCode: [] as any,
  };

  const [pinCodeObj, setPinCodeObj] = useState<any>(initialPinCodeObj);

  const COLUMNS = [
    { headerName: "Area Name", field: "areaName", flex: 1 },
    {
      headerName: "PinCodes",
      field: "pinCode",
      flex: 1,
      renderCell: ({ row }: { row: Pincode }) => (
        <div>{row.pinCode.length}</div>
      ),
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: Pincode }) => (
        <div>
          <IconButton
            onClick={() => handleEdit(row)}
            aria-label="edit"
            style={{ marginRight: "8px", color: "#00C5B9" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(row)}
            aria-label="delete"
            style={{ color: "#00C5B9" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_PINCODES, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.getPinCodes?.data) {
      setList(data.getPinCodes.data);
      setTotal(data.getPinCodes.count);
    }
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page,
        limit: pagination.pageSize,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize, searchTerm]
  );

  const handleEdit = (rowData: Pincode) => {
    setOpenModal(true);
    setPinCodeObj(rowData);
    setIsEditPincode(true);
  };

  const handleDelete = (rowData: Pincode) => {
    setSelectedPincode(rowData);
    setOpenConfirmDialog(true);
  };

  const confirmDelete = async () => {
    try {
      if (selectedPincode) {
        const res = await dispatch(ACTION_DELETE_PINCODE(selectedPincode._id));
        if (res?.type === "DELETE_PINCODE_ACTION_SUCCESS") {
          toast.success(
            res?.payload?.deletePinCode?.message ||
              "Pincode deleted successfully!"
          );
          refetch();
        } else {
          throw new Error(res?.payload);
        }
      }
    } catch (error: any) {
      toast.error(
        error?.message || "An error occurred while deleting the pincode."
      );
    } finally {
      setOpenConfirmDialog(false);
      setSelectedPincode(null);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setPinCodeObj(initialPinCodeObj);
    setIsEditPincode(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage + 1 }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: newPageSize,
    });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Pincode
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box width={100}>
            <CustomButton
              onClick={() => setOpenModal(true)}
              variant="contained"
              className="width: 200px"
            >
              Create
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <PincodeModal
        open={openModal}
        handleClose={handleCloseModal}
        refetchPincodes={refetch}
        initialPinCodeObj={initialPinCodeObj}
        pinCodeObj={pinCodeObj}
        setPinCodeObj={setPinCodeObj}
        isEditPincode={isEditPincode}
      />
      <DeleteModel
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={confirmDelete}
        title="Confirm Deletion"
        message="Are you sure you want to delete this pincode?"
      />
    </Box>
  );
};

export default Pincode;
