import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModel from "src/components/Common/DeleteModel";
import { GET_REVIEWS } from "src/graphql/query";
import { DELETE_REVIEW } from "src/graphql/mutations";

interface Review {
  _id: string;
  businessProfileId: string;
  ratings: number;
  title: string;
  description: string;
  authorName: string | null;
  authorPosition: string | null;
  profileImage: string | null;
  city: string | null;
  createdAt: string | null;
}

const Review: React.FC = () => {
  const [list, setList] = useState<Review[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [total, setTotal] = useState(0);
  const [deleteReview] = useMutation(DELETE_REVIEW);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteReviewId, setDeleteReviewId] = useState<string | null>(null);

  const COLUMNS = [
    { headerName: "Title", field: "title", flex: 1 },
    { headerName: "Description", field: "description", flex: 2 },
    { headerName: "Ratings", field: "ratings", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: Review }) => (
        <div>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setDeleteReviewId(row._id);
                setOpenDeleteModel(true);
              }}
              aria-label="delete"
              style={{ color: "#00C5B9" }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_REVIEWS, {
    variables: {
      page: pagination.page + 1, // Page numbers are usually 1-based on the server
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true, // Notify on network status change
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1, // Page numbers are usually 1-based on the server
        limit: pagination.pageSize,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getReviews?.data) {
      setList(data.getReviews.data);
      setTotal(data.getReviews.count);
    }
  }, [data]);

  const handleDelete = async () => {
    if (!deleteReviewId) return;
    try {
      await deleteReview({
        variables: { _id: deleteReviewId },
      });
      refetch(); // Re-fetch data after deletion
      setOpenDeleteModel(false); // Close the confirmation dialog
    } catch (error) {
      console.error("Error deleting review:", error);
    }
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteReviewId(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Reviews
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this review?"
        />
      )}
    </Box>
  );
};

export default Review;
