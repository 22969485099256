import React, { useEffect, useState, useCallback } from "react";
import { Box, TextField, Typography, Divider } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_fAQ } from "src/graphql/mutations";
import { GET_FAQS } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import FaqModal from "./FaqModal";
import DeleteModel from "src/components/Common/DeleteModel";
import { toast } from "react-toastify";

export interface Faq {
  _id: string;
  question: string;
  answer: string;
}

const Faq: React.FC = () => {
  const [list, setList] = useState<Faq[]>([]);
  const [selectedFaq, setSelectedFaq] = useState<Faq | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [total, setTotal] = useState(0);
  const [deleteFaq] = useMutation(DELETE_fAQ);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteFaqId, setDeleteFaqId] = useState<string | null>(null);

  const COLUMNS = [
    { headerName: "Question", field: "question", flex: 1 },
    { headerName: "Answer", field: "answer", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <div>
          <IconButton
            onClick={() => handleEdit(row)}
            aria-label="edit"
            style={{ marginRight: "8px", color: "#00C5B9" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setDeleteFaqId(row._id);
              setOpenDeleteModel(true);
            }}
            aria-label="delete"
            style={{ color: "#00C5B9" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_FAQS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getFaqs?.data) {
      setList(data.getFaqs.data);
    }
    setTotal(data?.getFaqs?.count);
  }, [data]);

  const handleEdit = (rowData: any) => {
    setSelectedFaq(rowData);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    if (!deleteFaqId) return;
    try {
      const res = await deleteFaq({
        variables: { id: deleteFaqId },
      });
      if (res?.errors) {
        throw new Error(res?.errors as any);
      } else {
        toast.success(res?.data?.deleteFaq?.message);
        refetch();
        setOpenDeleteModel(false);
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedFaq(null);
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteFaqId(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage === pagination?.page) {
      return;
    }
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Faq
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box width={100}>
            <CustomButton
              onClick={() => setOpenModal(true)}
              variant="contained"
              className="width: 200px"
            >
              Create
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      <FaqModal
        open={openModal}
        handleClose={handleCloseModal}
        faq={selectedFaq}
        refetchFaq={refetch}
      />
      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this FAQ?"
        />
      )}
    </Box>
  );
};

export default Faq;
