import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Button,
  Grid,
  debounce,
} from "@mui/material";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import {
  ADD_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  UPDATE_SUB_CATEGORY,
} from "src/graphql/mutations";
import { GET_SUB_CATEGORY, GET_SUBCATEGORY } from "src/graphql/query";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadImages } from "../Common/Utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import backButton from "../../Images/backButton.svg";
import DeleteModel from "src/components/Common/DeleteModel"; // Import DeleteModel

interface SubCategory {
  _id: string;
  subCategoryName: string;
}

interface BannerImage {
  imageUrl: string;
  redirectLink: string;
  Title: string;
  Description: string;
}

const SubCategory: React.FC = () => {
  const [list, setList] = useState<SubCategory[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false); // State for delete confirmation dialog
  const [categoryName, setCategoryName] = useState<string>("");
  const [bannerImages, setBannerImages] = useState<BannerImage[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [subCategoryId, setSubCategoryId] = useState<string | null>(null);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [errors, setErrors] = useState<any>({});
  const [deleteId, setDeleteId] = useState<string | null>(null); // State to store the id of the subcategory to delete
  const [AddSubCategory] = useMutation(ADD_SUB_CATEGORY);
  const [deleteCategory] = useMutation(DELETE_SUB_CATEGORY);
  const [updateCategory] = useMutation(UPDATE_SUB_CATEGORY);
  const navigate = useNavigate();
  const client = useApolloClient();
  const { id } = useParams<{ id: string }>();

  const validationSchema = Yup.object().shape({
    subCategoryName: Yup.string().required("Sub Category Name is required"),
    description: Yup.string().required("Description is required"),
    metaTitle: Yup.string().required("Meta Title is required"),
    metaKeywords: Yup.string().required("Meta Keywords is required"),
    metaDescription: Yup.string().required("Meta Description is required"),
  });

  //Form initialize
  const formic = useFormik({
    initialValues: {
      subCategoryName: "",
      description: "",
      metaTitle: "",
      metaKeywords: "",
      metaDescription: "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => handleSubmit,
  });

  //Initialize table data
  const COLUMNS = [
    { headerName: "Sub Category Name", field: "subCategoryName", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <div>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(row);
            }}
            aria-label="edit"
            style={{ marginRight: "8px", color: "#00C5B9" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label="delete"
            style={{ color: "#00C5B9" }}
            onClick={(e) => {
              e.stopPropagation();
              setDeleteId(row._id); // Set the id of the subcategory to delete
              setOpenDeleteModal(true); // Open delete confirmation dialog
            }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  //Fetch Sub category
  const { loading, error, data, refetch } = useQuery(GET_SUB_CATEGORY, {
    variables: {
      _id: id,
      subCategoryPage: pagination.page,
      subCategoryLimit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  //To Empty Data
  const setEmptyData = () => {
    formic.resetForm();
  };

  useEffect(() => {
    if (data?.getCategoryByIdWithPaginatedSubcategories?.data) {
      setList(
        data?.getCategoryByIdWithPaginatedSubcategories?.data?.subCategory
      );
      setCategoryName(
        data?.getCategoryByIdWithPaginatedSubcategories?.data?.categoryName
      );
    }
  }, [data]);
  //handle change form values
  const customHandleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formic.handleChange(e);
    const { name, value } = e.target;
    setList((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  //Edit Subcategory
  const handleEdit = async (rowData: { _id: string }) => {
    try {
      const { data } = await client.query({
        query: GET_SUBCATEGORY,
        variables: { _id: rowData?._id },
        fetchPolicy: "network-only",
      });
      const subCategoryData = data?.getSubCategory?.data;
      if (subCategoryData) {
        formic.setValues({
          description: subCategoryData?.description || "",
          metaDescription: subCategoryData?.metaDescription || "",
          metaKeywords: subCategoryData?.metaKeywords || "",
          metaTitle: subCategoryData?.metaTitle || "",
          subCategoryName: subCategoryData?.subCategoryName || "",
        });
      }

      setSubCategoryId(subCategoryData?._id);

      // Remove _typname from bannerImages
      const cleanBannerImages = (subCategoryData?.bannerImages || []).map(
        ({ __typename, ...rest }: any) => rest
      );
      setBannerImages(cleanBannerImages);

      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching subcategory data:", error);
    }
  };

  //Delete subcategory
  const handleDelete = async (id: string) => {
    try {
      await deleteCategory({
        variables: { _id: id },
      });
      toast.success("Subcategory deleted successfully!");
      setOpenDeleteModal(false);
      refetch();
    } catch (error) {
      toast.error("Error deleting subcategory.");
    }
  };

  //Pagination
  const handlePageChange = async (newPage: number) => {
    await setPagination((prev) => ({ ...prev, page: newPage + 1 }));
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: pagination.pageSize,
    });
  };

  //Row click
  const handleRowClick = (id: string) => {
    navigate(`/subcategory/${id}`);
  };

  const handlePageSizeChange = (newPageSize: number) => {};

  //Banner Image function
  const handleBannerImageChange = (
    index: number,
    file: File | null,
    redirectLink: string,
    Title: string,
    Description: string
  ) => {
    const updatedBannerImages = [...bannerImages];
    const imageUrl = file
      ? URL.createObjectURL(file)
      : updatedBannerImages[index]?.imageUrl || "";
    updatedBannerImages[index] = { imageUrl, redirectLink, Title, Description };
    setBannerImages(updatedBannerImages);
  };

  const handleAddBannerImage = () => {
    setBannerImages([
      ...bannerImages,
      { imageUrl: "", redirectLink: "", Title: "", Description: "" },
    ]);
  };

  const handleRemoveBannerImage = (index: number) => {
    const updatedBannerImages = [...bannerImages];
    updatedBannerImages.splice(index, 1);
    setBannerImages(updatedBannerImages);
  };

  //Submit response
  const handleSubmit = async () => {
    try {
      // Validate the form values using Yup validation schema
      await validationSchema.validate(
        { ...formic.values },
        { abortEarly: false }
      );

      // Prepare files for upload by converting blob URLs to files
      const filesToUpload = bannerImages.map((banner) => {
        const fileUrl = banner.imageUrl;
        if (fileUrl.includes("blob:")) {
          return fetch(fileUrl)
            .then((res) => res.blob())
            .then((blob) => new File([blob], "image", { type: blob.type }))
            .catch((error) => {
              console.error("Error converting blob to file:", error);
              return null;
            });
        }
        return null;
      });

      // Resolve all file upload promises
      const resolvedFiles = await Promise.all(filesToUpload);
      const filesToUploadFiltered = resolvedFiles.filter(Boolean) as File[];

      // Upload files if any new files exist
      const uploadResult = filesToUploadFiltered.length
        ? await uploadImages(filesToUploadFiltered)
        : [];

      // Prepare the variables for API request
      const variables = {
        ...formic.values,
        bannerImages: bannerImages.map((banner, index) => ({
          imageUrl: uploadResult[index] || banner.imageUrl,
          redirectLink: banner.redirectLink,
          Title: banner.Title,
          Description: banner.Description,
        })),
      };

      // Update or add the subcategory based on existence
      if (subCategoryId) {
        await updateCategory({
          variables: { _id: subCategoryId, input: variables },
        });
        toast.success("Subcategory updated successfully!");
      } else {
        await AddSubCategory({
          variables: {
            input: {
              ...variables,
              categoryId: id,
            },
          },
        });
        toast.success("Subcategory added successfully!");
      }

      // Close modal, refetch data, and reset state
      setOpenModal(false);
      refetch();
      setEmptyData();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrors(
          err.inner.reduce((acc, error) => {
            if (error.path) {
              acc[error.path] = error.message;
            }
            return acc;
          }, {} as any)
        );
      } else {
        console.error("An error occurred:", err);
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  //Debounce search
  const handleSearchChange = useCallback(
    debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      refetch({
        search: event.target.value,
        page: pagination.page,
        limit: pagination.pageSize,
      });
    }, 500),
    [pagination.page, pagination.pageSize]
  );

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return openModal ? (
    <form onSubmit={handleSubmit}>
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={backButton}
              alt="backButton"
              height={25}
              width={25}
              style={{ marginRight: "12px", cursor: "pointer" }}
              onClick={() => setOpenModal(false)}
            />
            <Typography variant="h4" style={{ color: "#00C5B9" }}>
              {categoryName}
            </Typography>
          </div>
        </Box>
        <Divider />

        <TextField
          margin="dense"
          label="SubCategory Name"
          name="subCategoryName"
          fullWidth
          value={formic.values.subCategoryName}
          onChange={customHandleChange}
          error={!!errors.subCategoryName}
          helperText={errors.subCategoryName}
        />
        <TextField
          margin="dense"
          label="Description"
          fullWidth
          name="description"
          value={formic.values.description}
          onChange={customHandleChange}
          error={!!errors.description}
          helperText={errors.description}
        />

        <TextField
          margin="dense"
          label="Meta Title"
          fullWidth
          name="metaTitle"
          value={formic.values.metaTitle}
          onChange={customHandleChange}
          error={!!errors.metaTitle}
          helperText={errors.metaTitle}
        />
        <TextField
          margin="dense"
          label="Meta Description"
          fullWidth
          name="metaDescription"
          value={formic.values.metaDescription}
          onChange={customHandleChange}
          error={!!errors.metaDescription}
          helperText={errors.metaDescription}
        />
        <TextField
          margin="dense"
          label="Meta Keywords"
          fullWidth
          name="metaKeywords"
          value={formic.values.metaKeywords}
          onChange={customHandleChange}
          error={!!errors.metaKeywords}
          helperText={errors.metaKeywords}
        />

        {/* Banner Images Section */}
        <Divider style={{ margin: "16px 0" }} />
        <Typography variant="h6" gutterBottom>
          Banner Images
        </Typography>

        {bannerImages.map((banner, index) => (
          <Grid container spacing={2} key={index} alignItems="flex-start">
            <Grid item xs={12} sm={6} md={3}>
              <input
                type="file"
                onChange={(e) =>
                  handleBannerImageChange(
                    index,
                    e.target.files?.[0] || null,
                    banner.redirectLink,
                    banner.Title,
                    banner.Description
                  )
                }
                style={{ display: "none" }}
                id={`banner-upload-${index}`}
              />
              <label htmlFor={`banner-upload-${index}`}>
                <Box
                  sx={{
                    width: "100%",
                    height: 200,
                    marginTop: "10px",
                    borderRadius: 2,
                    border: "2px dashed #00C5B9",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    overflow: "hidden",
                  }}
                >
                  {banner.imageUrl ? (
                    <img
                      src={banner.imageUrl}
                      alt={`banner-${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <Typography color="primary">Upload Image</Typography>
                  )}
                </Box>
              </label>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Redirect Link"
                fullWidth
                value={banner.redirectLink}
                onChange={(e) =>
                  handleBannerImageChange(
                    index,
                    null,
                    e.target.value,
                    banner.Title,
                    banner.Description
                  )
                }
                margin="normal"
              />
              <TextField
                label="Title"
                fullWidth
                value={banner.Title}
                onChange={(e) =>
                  handleBannerImageChange(
                    index,
                    null,
                    banner.redirectLink,
                    e.target.value,
                    banner.Description
                  )
                }
                margin="normal"
              />
              <TextField
                label="Description"
                fullWidth
                value={banner.Description}
                onChange={(e) =>
                  handleBannerImageChange(
                    index,
                    null,
                    banner.redirectLink,
                    banner.Title,
                    e.target.value
                  )
                }
                margin="normal"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              container
              justifyContent="flex-end"
              mt={3}
            >
              <CustomButton
                variant="outlined"
                color="secondary"
                onClick={() => handleRemoveBannerImage(index)}
              >
                Remove Image
              </CustomButton>
            </Grid>
          </Grid>
        ))}

        <CustomButton
          variant="outlined"
          onClick={handleAddBannerImage}
          style={{ marginTop: "16px" }}
        >
          Add Banner Image
        </CustomButton>

        <Box
          width={100}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            marginInline: "auto",
            marginTop: "16px",
          }}
        >
          <CustomButton onClick={handleSubmit} variant="contained">
            Submit
          </CustomButton>
        </Box>
      </Box>
    </form>
  ) : (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleBackButtonClick}
          />
          <Typography variant="h4" style={{ color: "#00C5B9" }}>
            {categoryName}
          </Typography>
        </div>

        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box width={100}>
            <CustomButton
              onClick={() => setOpenModal(true)}
              variant="contained"
            >
              Create
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page - 1,
            pageSize: pagination.pageSize,
          }}
          totalCount={
            data?.getCategoryByIdWithPaginatedSubcategories?.data
              ?.subCategoryCount
          }
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onRowClick={handleRowClick}
        />
      </Box>
      <DeleteModel
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={() => deleteId && handleDelete(deleteId)}
        message="Are you sure you want to delete this deal?"
      />
    </Box>
  );
};

export default SubCategory;
