import { FormControl } from "@mui/material";
import React from "react";
import Select, { SingleValue } from "react-select";

// Define types for props
interface businessOptions {
  value: string;
  label: string;
}

interface CategorySelectDropDownProps {
  selectedBusiness: string | null;
  handleBusinessChange: (selectedOption: SingleValue<businessOptions>) => void;
  businessOptions: businessOptions[];
}

const BusinessSelectDropDown: React.FC<CategorySelectDropDownProps> = ({
  selectedBusiness,
  handleBusinessChange,
  businessOptions,
}) => {
  return (
    <FormControl fullWidth sx={{ zIndex: "9999" }}>
      <Select
        name="businessProfileId"
        value={businessOptions.find(
          (option) => option.value === selectedBusiness
        )}
        maxMenuHeight={300}
        placeholder="Select Business"
        options={businessOptions}
        onChange={handleBusinessChange}
      />
    </FormControl>
  );
};

export default BusinessSelectDropDown;
