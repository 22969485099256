import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { CREATE_fAQ, UPDATE_fAQ } from "src/graphql/mutations";
import CustomButton from "src/components/CustomButton";
import { toast } from "react-toastify";
import { Faq } from "./index";
interface FaqModal {
  open: boolean;
  handleClose: () => void;
  faq?: Faq | null;
  refetchFaq: () => void;
}

// Use the defined type in the functional component
const FaqModal: React.FC<FaqModal> = ({
  open,
  handleClose,
  faq,
  refetchFaq,
}) => {
  const [question, setQuestion] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");

  const [createFaq] = useMutation(CREATE_fAQ);
  const [UpdateFaq] = useMutation(UPDATE_fAQ);

  useEffect(() => {
    if (faq) {
      setQuestion(faq.question || "");
      setAnswer(faq.answer || "");
    }
  }, [faq]);

  const handleSubmit = async () => {
    try {
      if (faq) {
        const res = await UpdateFaq({
          variables: { id: faq._id, input: { question, answer } },
        });
        if (res?.errors) {
          throw new Error(res.errors as any);
        }
        toast.success(
          res?.data?.updateFaq?.message || "Faq updated successfully!"
        );
      } else {
        await createFaq({
          variables: { input: { question, answer } },
        });
        toast.success("FAQ created successfully!");
      }
      refetchFaq();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ color: "#00C5B9" }}>
        {faq ? "Update Faq" : "Create Faq"}
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Question"
          fullWidth
          required={true}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Answer"
          required={true}
          fullWidth
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Box width={100}>
          <CustomButton onClick={handleClose} variant="outlined">
            Cancel
          </CustomButton>
        </Box>
        <Box width={100}>
          <CustomButton onClick={handleSubmit} variant="contained">
            {faq ? "Update" : "Create"}
          </CustomButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FaqModal;
