import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { OverviewComponentProps } from "./Business";

const OverviewComponent: React.FC<OverviewComponentProps> = ({ data }) => {
  const theme = useTheme();
  const selectedOptions: Record<string, any> =
    data?.dynamicOptions &&
    data?.dynamicOptions?.reduce((acc: any, option: any) => {
      if (option.type === "checkbox") {
        acc[option.id] = option.options.reduce(
          (optionAcc: Record<string, boolean>, item: any) => {
            optionAcc[item.id] = item.isSelect ?? false;
            return optionAcc;
          },
          {}
        );
      } else if (option?.type === "dropdown" || option?.type === "radio") {
        acc[option?.id] = option?.value || "";
      }
      return acc;
    }, {});

  return (
    <Grid container spacing={3}>
      {data?.dynamicOptions?.map((option) => (
        <Grid item xs={12} key={option.id}>
          {option?.type === "checkbox" && (
            <FormControl component="fieldset" sx={{ width: "100%" }}>
              <FormLabel component="legend">
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {option.display}
                </Typography>
              </FormLabel>
              <FormGroup>
                {option.options.map((item) => (
                  <FormControlLabel
                    key={item?.id}
                    control={
                      <Checkbox
                        checked={selectedOptions[option.id][item.id]}
                        disabled
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    }
                    label={
                      <Typography sx={{ color: theme.palette.text.primary }}>
                        {item?.label}
                      </Typography>
                    }
                  />
                ))}
              </FormGroup>
            </FormControl>
          )}

          {option?.type === "dropdown" && (
            <FormControl fullWidth sx={{ marginTop: theme.spacing(2) }}>
              <FormLabel>
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {option?.display}
                </Typography>
              </FormLabel>
              <Select
                value={selectedOptions[option?.id]}
                displayEmpty
                disabled
                sx={{
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.background.paper,
                  "& .MuiSelect-icon": {
                    color: theme.palette.secondary.main,
                  },
                }}
              >
                {option.options.map((item) => (
                  <MenuItem key={item.id} value={item.value ?? ""}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {option?.type === "radio" && (
            <FormControl
              component="fieldset"
              sx={{ marginTop: theme.spacing(2) }}
            >
              <FormLabel component="legend">
                <Typography
                  variant="h6"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {option?.display.replace("_", " ")}
                </Typography>
              </FormLabel>
              <RadioGroup value={selectedOptions[option?.id]}>
                {option?.options.map((item) => (
                  <FormControlLabel
                    key={item.id}
                    value={item ?? ""}
                    control={
                      <Radio
                        disabled
                        sx={{ color: theme.palette.secondary.main }}
                      />
                    }
                    label={
                      <Typography sx={{ color: theme.palette.text.primary }}>
                        {item as any}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default OverviewComponent;
