/**
 * Checks if localStorage is available.
 * @returns {boolean} - True if localStorage is available, otherwise false.
 */
const isLocalStorageAvailable = () => {
  try {
    const test = "__localStorageTest__";
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Sets an item in localStorage.
 * @param {string} key - The key to set in localStorage.
 * @param {any} value - The value to set in localStorage. It will be stringified.
 */
export const localStorageSetItem = (key: string, value: any) => {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

/**
 * Gets an item from localStorage.
 * @param {string} key - The key to get from localStorage.
 * @returns {any} - The parsed value from localStorage. If parsing fails, returns null.
 */
export const localStorageGetItem = (key: string) => {
  if (isLocalStorageAvailable()) {
    const value = localStorage.getItem(key) || "";
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }
  return null;
};

/**
 * Removes an item from localStorage.
 * @param {string} key - The key to remove from localStorage.
 */
export const removeItem = (key: string) => {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key);
  }
};

/**
 * Clears all items from localStorage.
 */
export const clear = () => {
  if (isLocalStorageAvailable()) {
    localStorage.clear();
  }
};
