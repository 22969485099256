import React from "react";
import Carousel from "react-material-ui-carousel";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import OverviewComponent from "./OverView";
import { BusinessProfileProps } from "./Business";

const BusinessProfileView: React.FC<BusinessProfileProps> = ({
  businessProfile,
}) => {
  const {
    businessName,
    brandName,
    brandLogo,
    bgImage,
    overviews,
    subCategory,
    mobileNumber,
    timings,
    address,
    galleries,
    user,
  } = businessProfile;
  return (
    <Box p={3} sx={{ maxWidth: 1200, mx: "auto" }}>
      <Card sx={{ mb: 3, borderRadius: 2, overflow: "hidden" }}>
        <CardMedia
          component="img"
          height="200"
          image={bgImage}
          alt={businessName}
          sx={{ objectFit: "cover" }}
        />
        <CardContent>
          <Typography variant="h4" component="div" gutterBottom>
            {businessName}
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            {brandName}
          </Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <CardMedia
              component="img"
              sx={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                mr: 2,
                border: "2px solid #ddd",
              }}
              image={brandLogo}
              alt={brandName}
            />
            <Box>
              <Typography variant="body1" fontWeight="bold">
                {/* {category} */}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {subCategory}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Mobile: {mobileNumber}
              </Typography>
              {user?.email && (
                <Typography variant="body2" color="textSecondary">
                  Email: {user.email}
                </Typography>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>

      {galleries?.length > 0 && (
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            Gallery
          </Typography>
          <Carousel>
            {galleries[0].image.map((img, index) => (
              <CardMedia
                key={index}
                component="img"
                height="300"
                image={img}
                alt={`Gallery image ${index + 1}`}
                sx={{ objectFit: "cover" }}
              />
            ))}
          </Carousel>
        </Box>
      )}

      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          Timings
        </Typography>
        <Grid container spacing={2}>
          {timings[0]?.timings?.map((timing: any, index: any) => (
            <Grid item xs={12} sm={6} key={index}>
              <Card variant="outlined" sx={{ borderRadius: 2 }}>
                <CardContent>
                  <Typography variant="h6">{timing.day}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {timing.startTime} - {timing.endTime}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {address?.addressses?.length > 0 && (
        <Box mb={3}>
          <Typography variant="h6" gutterBottom>
            Address
          </Typography>
          {address?.addressses?.map((addr: any, index: any) => (
            <Box key={index} mb={1}>
              <Typography variant="body2" mb={0.5}>
                {addr?.address}
              </Typography>
              <Link href={addr.googleMapLink} target="_blank" rel="noopener">
                View on Google Maps
              </Link>
            </Box>
          ))}

          <Typography variant="h4" gutterBottom>
            Overview
          </Typography>
          <OverviewComponent data={overviews[0]} />
        </Box>
      )}
    </Box>
  );
};

export default BusinessProfileView;
