import { ApolloProvider } from "@apollo/client";
import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router";
// eslint-disable-next-line no-restricted-imports
import "./App.css";
import client from "./apolloClient";
import AuthLayout from "./layout/AuthLayout";
import PrivateLayout from "./layout/PrivateLayout";
import RoutesList from "./routes";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import disableDevtool from "disable-devtool";

const App = () => {
  const userDataSelector = useSelector((state: any) => state.authReducer);
  const user = userDataSelector.users;
  const renderRoutes = () => {
    const isLogin = !!user;

    const renderRoute = (
      Component: React.FC,
      layout: string,
      isPrivate: boolean
    ) => {
      if (Component) {
        switch (layout) {
          case "private":
            return isLogin && isPrivate ? (
              <PrivateLayout>
                <Component />
              </PrivateLayout>
            ) : (
              <Navigate to="/" />
            );
          case "auth":
          default:
            return (
              <AuthLayout>
                <Component />
              </AuthLayout>
            );
          // );
          // case "public":
          // default:
          //   return (
          //     <PublicLayout>
          //       <Component />
          //     </PublicLayout>
          //   );
        }
      }
      return null;
    };

    return RoutesList.map((route: any) => (
      <Route
        key={route.name}
        path={route.path}
        element={renderRoute(route.component, route.layout, route.isPrivate)}
      />
    ));
  };
  // useEffect(() => {
  //   disableDevtool();
  // }, []);

  return (
    <div className="App">
      <ApolloProvider client={client}>
        <Routes>{renderRoutes()}</Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{
            zIndex: 999999999,
          }}
        />
      </ApolloProvider>
    </div>
  );
};

export default App;
