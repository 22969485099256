import React, { useState } from "react";
import {
  Box,
  Typography,
  Modal,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BusinessCard from "./BusinessCard";
import BusinessProfileView from "src/pages/Business/BusinessProfile";
import { Business } from "./types";
import CustomButton from "src/components/CustomButton";

interface BusinessListProps {
  businesses: Business[];
}

const BusinessList: React.FC<BusinessListProps> = ({ businesses }) => {
  const [selectedBusiness, setSelectedBusiness] = useState<any>(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (business: Business) => {
    setSelectedBusiness(business);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedBusiness(null);
    setOpen(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Businesses
      </Typography>
      <Grid container spacing={2}>
        {businesses?.map((business) => (
          <Grid item xs={12} sm={6} md={4} key={business._id}>
            <BusinessCard business={business} onViewDetails={handleOpen} />
          </Grid>
        ))}
      </Grid>

      {/* Modal for displaying business details */}
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            maxWidth: 800,
            margin: "auto",
            my: 10,
            padding: 3,
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 3,
            position: "relative",
            overflow: "auto",
            maxHeight: "90vh",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {selectedBusiness && (
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="md"
              fullWidth
              style={{ marginTop: "40px" }}
            >
              <DialogTitle>Business Profile</DialogTitle>
              <DialogContent>
                <BusinessProfileView businessProfile={selectedBusiness} />
              </DialogContent>
              <DialogActions>
                <Box>
                  <CustomButton
                    onClick={() => setOpen(false)}
                    color="primary"
                    variant="outlined"
                  >
                    Close
                  </CustomButton>
                </Box>
              </DialogActions>
            </Dialog>
            // <BusinessProfileView businessProfile={selectedBusiness} />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default BusinessList;
