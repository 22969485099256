import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
} from "@mui/material";
import Select from "react-select";
import CustomButton from "src/components/CustomButton";

interface VerifyEventsDialogProps {
  verifyingData: string | null;
  setVerifyingData: React.Dispatch<React.SetStateAction<string | null>>;
  handleVerify: (eventId: string, status: string, reason: string) => void;
  verifyDataValue: string;
  reason: string;
  setVerifyDataValue: React.Dispatch<React.SetStateAction<string>>;
  setReason: React.Dispatch<React.SetStateAction<string>>;
}

const VerifyEventsDialog = ({
  verifyingData,
  setVerifyingData,
  handleVerify,
  verifyDataValue,
  reason,
  setVerifyDataValue,
  setReason,
}: VerifyEventsDialogProps) => {
  return (
    <Dialog
      open={!!verifyingData}
      onClose={() => setVerifyingData(null)}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          height: "400px", // Fixed height
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>Verify Events</DialogTitle>
      <DialogContent style={{ flex: 1, overflow: "auto" }}>
        <FormControl fullWidth sx={{ zIndex: "9999", marginBottom: "4px" }}>
          <Select
            value={
              verifyDataValue
                ? { value: verifyDataValue, label: verifyDataValue }
                : null
            }
            onChange={(option) => setVerifyDataValue(option?.value || "")}
            options={[
              { value: "APPROVED", label: "Approved" },
              { value: "REJECTED", label: "Rejected" },
            ]}
          />
        </FormControl>

        <TextField
          margin="dense"
          label="Reason"
          fullWidth
          variant="outlined"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          multiline
          rows={4}
        />
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={() => setVerifyingData(null)} variant="outlined">
          Cancel
        </CustomButton>
        <CustomButton
          onClick={() => {
            if (verifyingData && verifyDataValue) {
              handleVerify(verifyingData, verifyDataValue, reason);
            }
          }}
          style={{
            color: "#ffffff",
            ...(verifyDataValue && !(verifyDataValue === "REJECTED" && !reason)
              ? {}
              : {
                  backgroundColor: "#cccccc",
                  cursor: "not-allowed",
                  color: "#bbbbbb",
                }),
          }}
          disabled={
            !verifyDataValue || (verifyDataValue === "REJECTED" && !reason)
          }
        >
          Verify
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default VerifyEventsDialog;
