import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { ADD_PINCODE_MUTATION, UPDATE_PINCODE } from "src/graphql/mutations";
import CustomButton from "src/components/CustomButton";
import { toast } from "react-toastify";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  areaName: yup.string().required("Area Name is required"),
  pinCode: yup
    .array()
    .of(
      yup.object().shape({
        city: yup.string().required("City is required"),
        pincode: yup
          .string()
          .matches(/^[0-9]{6}$/, "Each Pin Code must be a 6-digit number")
          .required("Pin Code is required"),
      })
    )
    .min(1, "At least one Pin Code is required"),
});

interface Pincode {
  city: string;
  postoffice: string;
  pincode: string;
}

interface PincodeModalProps {
  open: boolean;
  handleClose: () => void;
  refetchPincodes: () => void;
  initialPinCodeObj?: { areaName: string; pinCode: Pincode[] };
  pinCodeObj?: {
    _id: any;
    areaName: string;
    pinCode: Pincode[];
    __typename?: string;
  };
  setPinCodeObj?: (value: any) => void;
  isEditPincode?: boolean;
}

const PincodeModal: React.FC<PincodeModalProps> = ({
  open,
  handleClose,
  refetchPincodes,
  initialPinCodeObj = { areaName: "", pinCode: [] },
  pinCodeObj = {
    areaName: "",
    pinCode: [],
    _id: "",
    __typename: "",
    postoffice: "",
  },
  setPinCodeObj = () => {},
  isEditPincode = false,
}) => {
  const [errors, setErrors] = useState<any>({});
  const [newCity, setNewCity] = useState<string>("");
  const [newPinCode, setNewPinCode] = useState<string>("");
  const [newPostOffice, setNewPostOffice] = useState<string>("");
  const [addPinCodeMutation] = useMutation(ADD_PINCODE_MUTATION);
  const [updatePinCodeMutation] = useMutation(UPDATE_PINCODE);

  useEffect(() => {
    if (isEditPincode && pinCodeObj) {
      setPinCodeObj(pinCodeObj);
    } else {
      setPinCodeObj(initialPinCodeObj);
    }
  }, []);

  const handleAddPinCode = () => {
    if (
      newCity.trim() &&
      newPinCode.trim().length === 6 &&
      /^\d+$/.test(newPinCode)
    ) {
      setPinCodeObj((prev: any) => ({
        ...prev,
        pinCode: [
          ...prev.pinCode,
          { city: newCity, pincode: newPinCode, postoffice: newPostOffice },
        ],
      }));
      setNewCity("");
      setNewPinCode("");
      setNewPostOffice("");
      setErrors({ ...errors, pinCode: "" });
    } else {
      setErrors({
        ...errors,
        pinCode:
          "Invalid input. City must be filled, and pin code must be 6 digits.",
      });
    }
  };

  const handleRemovePinCode = (index: number) => {
    setPinCodeObj((prev: any) => ({
      ...prev,
      pinCode: prev.pinCode.filter((_: any, i: number) => i !== index),
    }));
  };

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(pinCodeObj, { abortEarly: false });
      setErrors({});
      const { _id, __typename, ...restData } = pinCodeObj;
      const { data } = isEditPincode
        ? await updatePinCodeMutation({
            variables: { _id: _id, input: restData },
          })
        : await addPinCodeMutation({
            variables: { input: pinCodeObj },
          });

      if (
        data?.addPinCode?.statusCode === 200 ||
        data?.updatePinCode?.statusCode === 200
      ) {
        toast.success(
          data?.addPinCode?.message ||
            data?.updatePinCode?.message ||
            "Pincode saved successfully!"
        );
        setPinCodeObj(initialPinCodeObj);
        refetchPincodes();
        handleClose();
      }
    } catch (err: any) {
      if (err?.inner) {
        const validationErrors: any = {};
        err.inner.forEach((validationError: any) => {
          validationErrors[validationError.path] = validationError.message;
        });
        setErrors(validationErrors);
      } else {
        toast.error(err?.message || "An unexpected error occurred.");
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ color: "#00C5B9" }}>
        {isEditPincode ? "Update Pincode" : "Create Pincode"}
      </DialogTitle>
      <DialogContent>
        <TextField
          name="areaName"
          margin="dense"
          label="Area Name"
          fullWidth
          required
          value={pinCodeObj?.areaName || ""}
          onChange={(e) =>
            setPinCodeObj({ ...pinCodeObj, areaName: e.target.value })
          }
          error={!!errors.areaName}
          helperText={errors.areaName}
        />
        <Box display="flex" gap={2}>
          <TextField
            name="city"
            margin="dense"
            label="City"
            fullWidth
            value={newCity}
            onChange={(e) => setNewCity(e.target.value)}
            error={!!errors.pinCode}
            helperText={errors.pinCode}
          />
          <TextField
            name="postoffice"
            margin="dense"
            label="Post Office"
            fullWidth
            value={newPostOffice}
            onChange={(e) => setNewPostOffice(e.target.value)}
            error={!!errors.pinCode}
            helperText={errors.pinCode}
          />
          <TextField
            name="pincode"
            margin="dense"
            label="Pin Code"
            fullWidth
            value={newPinCode}
            onChange={(e) => setNewPinCode(e.target.value)}
            error={!!errors.pinCode}
            helperText={errors.pinCode}
          />
        </Box>
        <CustomButton
          variant="outlined"
          style={{ marginTop: "8px" }}
          onClick={handleAddPinCode}
        >
          Add Pin Code
        </CustomButton>
        <List sx={{ maxHeight: 500 }}>
          {pinCodeObj?.pinCode?.map((pinCode: Pincode, index: number) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleRemovePinCode(index)}
                >
                  <RemoveCircleIcon />
                </IconButton>
              }
            >
              <ListItemText
                primary={`City: ${pinCode?.city},Post OFfice: ${pinCode?.postoffice} Pincode: ${pinCode?.pincode}`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Box width={100}>
          <CustomButton onClick={handleClose} variant="outlined">
            Cancel
          </CustomButton>
        </Box>
        <Box width={100}>
          <CustomButton onClick={handleSubmit} variant="contained">
            {isEditPincode ? "Update" : "Create"}
          </CustomButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PincodeModal;
