import { toast } from "react-toastify";

export interface PincodeOption {
  value: string;
  label: string;
}
//For multiple image upload
export const uploadImages = async (imagesData: File[]) => {
  const url =
    process.env.REACT_APP_GRAPHQL_ENDPOINT ??
    "https://server.dev.hisurat.com/graphql";

  // Function to upload multiple images at once
  const uploadMultipleImages = async (images: File[]) => {
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({
        query: `
          mutation ($files: [Upload!]!) {
            uploadMultipleImages(files: $files) {
              encoding
              url
              filename
              mimetype
            }
          }
        `,
        variables: {
          files: new Array(images.length).fill(null),
        },
      })
    );

    const map: { [key: string]: string[] } = {};
    images.forEach((_, index) => {
      map[index.toString()] = [`variables.files.${index}`];
    });

    formData.append("map", JSON.stringify(map));

    images.forEach((image, index) => {
      formData.append(index.toString(), image);
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          "x-apollo-operation-name": "uploadMultipleImages",
          "apollo-require-preflight": "true",
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `HTTP error! status: ${response.status}, message: ${errorText}`
        );
      }

      const { data } = await response.json();
      return data.uploadMultipleImages;
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error("Error uploading images.");
    }
  };

  return uploadMultipleImages(imagesData);
};

//For single image upload
export const uploadImage = async (imageData: File): Promise<any> => {
  const url =
    process.env.REACT_APP_GRAPHQL_ENDPOINT ??
    "https://server.dev.hisurat.com/graphql";

  const formData = new FormData();
  formData.append(
    "operations",
    JSON.stringify({
      query: `
        mutation ($file: Upload!) {
          uploadImage(file: $file) {
            encoding
            url
            filename
            mimetype
          }
        }
      `,
      variables: {
        file: null,
      },
    })
  );
  formData.append("map", JSON.stringify({ "0": ["variables.file"] }));
  formData.append("0", imageData);

  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        "x-apollo-operation-name": "uploadImage",
        "apollo-require-preflight": "true",
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `HTTP error! status: ${response.status}, message: ${errorText}`
      );
    }

    const { data } = await response.json();
    return data;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error; // Rethrow the error to handle it in the calling function
  }
};
