import React, { useCallback, useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Divider,
  Button,
  CircularProgress,
  Card,
  CardContent,
  TextField,
  debounce,
} from "@mui/material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_REGION_DATA, GET_ALL_PINCODES } from "src/graphql/query";
import { useNavigate } from "react-router-dom";
import TopBusinessesTab from "./TopBusinessesTab";
import BusinessesTab from "./BusinessList";
import DealsTab from "./DealList";
import ReviewsTab from "./ReviewList";
import PopularLocationsTab from "./LocationList";
import backButton from "../../Images/backButton.svg";
import CustomButton from "src/components/CustomButton";

const RegionTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const navigate = useNavigate();
  const [pinCode, setPinCode] = useState<string>("");
  const [selectedPincode, setSelectedPincode] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const { loading, error, data, refetch } = useQuery(GET_REGION_DATA, {
    variables: { _id: pinCode },

    skip: !pinCode,
  });

  const [
    fetchPincodes,
    { loading: loadingPincodes, error: errorPincodes, data: dataPincodes },
  ] = useLazyQuery(GET_ALL_PINCODES);
  const pincodes = dataPincodes?.getAllPinCodes?.data;

  useEffect(() => {
    fetchPincodes();
  }, [fetchPincodes]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handlePincodeSelect = (pincode: string) => {
    setPinCode(pincode);
    setSelectedPincode(pincode);
    refetch({ _id: pincode });
  };
  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
      });
    }, 300),
    [refetch]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  if (loading || loadingPincodes)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  if (error || errorPincodes)
    return (
      <Box sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h6" color="error">
          Something went wrong! Please try again.
        </Typography>
      </Box>
    );

  const pinCodeDetails = data?.getPinCodeDetails;
  // if (!pinCodeDetails) return null;

  const topBusinessesData = pinCodeDetails?.topBusinesses || [];
  const businessesData = pinCodeDetails?.businesses || [];
  const dealsData = pinCodeDetails?.deals || [];
  const reviewsData = pinCodeDetails?.reviews || [];
  const locationData = pinCodeDetails?.popularLocations || [];

  const tabComponents = [
    <TopBusinessesTab data={topBusinessesData} refetch={refetch} />,
    <BusinessesTab businesses={businessesData} />,
    <DealsTab deals={dealsData} />,
    <ReviewsTab reviews={reviewsData} />,
    <PopularLocationsTab locations={locationData} />,
  ];

  const tabLabels = [
    "Top Businesses",
    "Businesses",
    "Deals",
    "Reviews",
    "Popular Locations",
  ];

  return (
    <Box sx={{ padding: 3 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Region
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
      </Box>
      <Divider />

      {/* Pincode Selection Section */}
      <Box mb={3} pt={2}>
        <Typography variant="h5" sx={{ color: "#00C5B9", mb: 2 }}>
          Select Region
        </Typography>
        <Box display="flex" flexWrap="wrap" gap={2}>
          {pincodes &&
            pincodes.map((pincodeObj: any, index: number) => (
              <Button
                key={index}
                variant="outlined"
                style={{
                  color:
                    selectedPincode === pincodeObj?._id
                      ? "secondary"
                      : "#197BA0",
                }}
                onClick={() => handlePincodeSelect(pincodeObj?._id)}
                sx={{
                  borderColor:
                    selectedPincode === pincodeObj?._id ? "#00C5B9" : undefined,
                  color:
                    selectedPincode === pincodeObj?._id ? "#00C5B9" : undefined,
                  "&:hover": {
                    borderColor: "#00C5B9",
                    color: "#00C5B9",
                  },
                }}
              >
                {pincodeObj?.areaName}
              </Button>
            ))}
        </Box>
        <Divider sx={{ my: 2 }} />
      </Box>

      {/* Business Data Section */}
      {selectedPincode && (
        <>
          <Box display="flex" alignItems="center" mb={2}>
            <img
              src={backButton}
              alt="Back button"
              height={25}
              width={25}
              style={{ marginRight: "12px", cursor: "pointer" }}
              onClick={handleBackButtonClick}
            />
            <Typography variant="h4" sx={{ color: "#00C5B9" }}>
              {pinCodeDetails.regionName}
            </Typography>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Box>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              aria-label="Region Tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{ borderBottom: "1px solid #e0e0e0" }}
            >
              {tabLabels.map((label, index) => (
                <Tab
                  key={index}
                  label={label}
                  sx={{
                    textTransform: "none",
                    minWidth: 100,
                    color: activeTab === index ? "#1976d2" : "#000",
                    fontWeight: activeTab === index ? "bold" : "normal",
                  }}
                />
              ))}
            </Tabs>
          </Box>
          <Box
            sx={{
              padding: 2,
              mt: 2,
              borderRadius: 2,
              boxShadow: 1,
              bgcolor: "#f5f5f5",
              minHeight: "400px",
            }}
          >
            {tabComponents[activeTab]}
          </Box>
        </>
      )}
    </Box>
  );
};

export default RegionTabs;
