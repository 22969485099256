import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Rating,
  Divider,
  Modal,
} from "@mui/material";

const TopBusinessCard = ({ business, onClick }: any) => {
  return (
    <Card
      sx={{ maxWidth: 345, margin: 2, boxShadow: 3 }}
      onClick={() => onClick(business)}
    >
      <CardMedia
        component="img"
        height="140"
        image={business?.image}
        alt={business?.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {business?.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {business?.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

const BusinessDetailsModal = ({ open, handleClose, business }: any) => {
  if (!business) return null;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="business-details-modal"
      sx={{ marginTop: "20px" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography id="business-details-modal" variant="h6" component="h2">
          {business?.title}
        </Typography>
        <CardMedia
          component="img"
          height="140"
          image={business?.image}
          alt={business?.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {business?.businessProfile?.businessName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {business?.description}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Typography variant="body1" color="text.primary">
            Contact: {business?.businessProfile?.mobileNumber}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Website: {business?.businessProfile?.website}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Email: {business?.businessProfile?.businessEmail}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Status: {business?.businessProfile?.status}
          </Typography>
          <Rating
            value={business?.businessProfile?.averageRating || 0}
            readOnly
          />
        </CardContent>
      </Box>
    </Modal>
  );
};

const TopBusinesses = ({ topBusinesses }: any) => {
  const [open, setOpen] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState<any>(null);

  const handleOpen = (business: any) => {
    setSelectedBusiness(business);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedBusiness(null);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Top Businesses
      </Typography>
      <Grid container spacing={2}>
        {topBusinesses?.map((business: any) => (
          <Grid item xs={12} sm={6} md={4} key={business._id}>
            <TopBusinessCard business={business} onClick={handleOpen} />
          </Grid>
        ))}
      </Grid>
      <Box mt={8}>
        <BusinessDetailsModal
          open={open}
          handleClose={handleClose}
          business={selectedBusiness}
        />
      </Box>
    </Box>
  );
};

export default TopBusinesses;
