import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  FormControl,
  IconButton,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE__NEWS } from "src/graphql/mutations";
import { GET_NEWS, GET_NEWS_DATA } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import NewsPage from "./NewsPage";
import moment from "moment";
import { toast } from "react-toastify";
import { NewsCategory, NewsTypes, newsCategoryOptions } from "./News";
import Select from "react-select";
import DeleteModel from "src/components/Common/DeleteModel";

const News: React.FC = () => {
  const [list, setList] = useState<NewsTypes[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [total, setTotal] = useState<number>(0);
  const [category, setCategory] = useState<NewsCategory>(NewsCategory.All);
  const [deleteNews] = useMutation(DELETE__NEWS);
  const [newsPage, setNewsPage] = useState<boolean>(false);
  const [newsData, setNewsData] = useState<any>();
  const [deleteNewsId, setDeleteNewsId] = useState<string | null>(null);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);

  const COLUMNS = [
    {
      headerName: "Date",
      field: "date",
      flex: 1,
      renderCell: ({ value }: { value: string }) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="body2">
            {moment(value).format("MMMM D, YYYY")}
          </Typography>
        </Box>
      ),
    },
    { headerName: "Title", field: "title", flex: 1 },
    { headerName: "Image", field: "image", flex: 1 },
    { headerName: "Source Type", field: "sourceType", flex: 1 },
    { headerName: "Category", field: "category", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => handleView(row)}
            aria-label="view"
            style={{ color: "#00C5B9" }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
          <IconButton
            onClick={() => handleOpenDeleteModel(row._id)}
            aria-label="delete"
            style={{ color: "#00C5B9", marginRight: 8 }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  const { refetch: refetchEventById } = useQuery(GET_NEWS_DATA, {
    skip: true, // Skip automatically running the query
  });

  const handleView = async (rowData: any) => {
    setNewsPage(true);
    try {
      const res = await refetchEventById({ _id: rowData?._id });
      setNewsData(res?.data?.getNewsById?.news);
    } catch (error) {
      console.error(error);
    }
    refetch();
  };

  const { loading, error, data, refetch } = useQuery(GET_NEWS, {
    variables: {
      page: pagination.page,
      limit: pagination.pageSize,
      category,
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page,
        limit: pagination.pageSize,
        category,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize, category]
  );

  useEffect(() => {
    if (data?.getNews?.news) {
      setList(data.getNews.news);
    }
    setTotal(data?.getNews?.count || 0);
  }, [data]);

  const handleOpenDeleteModel = (newsId: string) => {
    setDeleteNewsId(newsId);
    setOpenDeleteModel(true);
  };

  const handleDelete = async () => {
    if (!deleteNewsId) return;
    try {
      await deleteNews({
        variables: { id: deleteNewsId },
      });
      refetch();
      toast.success("News Deleted Successfully");
    } catch (error: any) {
      toast.error(error?.message || "Failed to delete news");
      console.error("Failed to delete news", error);
    } finally {
      setOpenDeleteModel(false);
      setDeleteNewsId(null);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePaginationChange = (page: any, pageSize: number) => {
    if (page === pagination.page) {
      return;
    }
    setPagination({ page, pageSize });
  };

  const handleCategoryChange = (event: any) => {
    const selectedCategory = event.target.value as NewsCategory;
    setCategory(selectedCategory);
    refetch({
      search: searchTerm,
      page: pagination.page,
      limit: pagination.pageSize,
      category: selectedCategory,
    });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      {newsPage ? (
        <NewsPage data={newsData} onClose={() => setNewsPage(false)} />
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h4" color="#00C5B9">
              News
            </Typography>
            <Box display="flex" alignItems="center" gap={2}>
              <Box width={250}>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </Box>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: 200 }}
              >
                <Select
                  value={newsCategoryOptions.find(
                    (option) => option.value === category
                  )}
                  onChange={handleCategoryChange}
                  options={newsCategoryOptions}
                  placeholder="Select Category"
                />
              </FormControl>
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <CustomTable
              columns={COLUMNS}
              data={list}
              paginationModel={{
                page: pagination.page - 1,
                pageSize: pagination.pageSize,
              }}
              totalCount={total}
              onPageChange={(page) => {
                handlePaginationChange(page + 1, pagination.pageSize);
              }}
              onPageSizeChange={(pageSize) =>
                handlePaginationChange(pagination.page, pageSize)
              }
            />
          </Box>
        </>
      )}

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this news?"
        />
      )}
    </Box>
  );
};

export default News;
