// LocationCard.tsx
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Link,
} from "@mui/material";
import { PopularLocation } from "./types";
import ReactMarkdown from "react-markdown";
import CustomButton from "src/components/CustomButton";
import PopularLocationDetails from "../PopularLocation/PopularLocationDetails";

interface LocationCardProps {
  location: PopularLocation;
}

const LocationCard: React.FC<LocationCardProps> = ({ location }) => {
  const [viewModel, setViewModel] = useState<boolean>(false);
  return (
    <Card sx={{ maxWidth: 345, margin: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" component="div" mb={2}>
          {location?.title}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" mb={2}>
          {location?.UserName}
        </Typography>
        {location.images.length > 0 && (
          <Box mb={2}>
            <CardMedia
              component="img"
              height="140"
              image={location?.images[0]}
              alt={`Location image `}
              sx={{ mb: 1 }}
            />
          </Box>
        )}
        <Typography variant="body2" color="text.secondary" mb={1}>
          {location.address}
        </Typography>
        <Link
          href={location.google_location_link}
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
        >
          View on Google Maps
        </Link>
        <Box mt={2}>
          {/* <ReactMarkdown>{location.about}</ReactMarkdown> */}
        </Box>
        <CustomButton onClick={() => setViewModel(true)}>
          View full details
        </CustomButton>
      </CardContent>
      {viewModel && (
        <PopularLocationDetails
          locationData={location}
          onClose={() => setViewModel(false)}
          open={viewModel}
        />
      )}
    </Card>
  );
};

export default LocationCard;
