import React, { useCallback, useEffect, useState } from "react";
import { Box, TextField, Typography, Divider, debounce } from "@mui/material";
import { useQuery } from "@apollo/client";
import moment from "moment";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import { GET_ALL_TRACKING_DATA } from "src/graphql/query";
import Select from "react-select";
import { TypeEnum, typeEnumOptions } from "./TrackingData";

interface TrackingData {
  _id: string;
  entityId: string;
  entityType: string;
  actionType: string;
  userId: string | null;
  timestamp: string;
}

const TrackingTable: React.FC = () => {
  const [list, setList] = useState<TrackingData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [total, setTotal] = useState(0);
  const [filterValue, SetFilterValue] = useState<any>();

  const COLUMNS = [
    { headerName: "Entity ID", field: "entityId", flex: 1 },
    { headerName: "Entity Type", field: "entityType", flex: 1 },
    { headerName: "Action Type", field: "actionType", flex: 1 },
    { headerName: "User ID", field: "userId", flex: 1 },
    {
      headerName: "Timestamp",
      field: "timestamp",
      flex: 1,
      renderCell: ({ value }: { value: string }) => (
        <span>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</span>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_ALL_TRACKING_DATA, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
      searchTerm,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.getAllTrackingData?.data) {
      setList(data.getAllTrackingData.data);
    }
    setTotal(data?.getAllTrackingData?.count);
  }, [data]);

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1, // Page numbers are usually 1-based on the server
        limit: pagination.pageSize,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize]
  );
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleCategoryChange = (option: any) => {
    SetFilterValue(option ? option.value : null);
    refetch({
      entityType: option ? option.value : null,
    });
  };
  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Tracking Data
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box my={4} width={150}>
            <Select
              options={typeEnumOptions}
              value={typeEnumOptions.find(
                (option) => option.value === filterValue
              )}
              onChange={handleCategoryChange}
              placeholder="Filter by Category"
              isClearable
            />
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
    </Box>
  );
};

export default TrackingTable;
