import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Box,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useFormik } from "formik";
import RichTextEditor from "src/components/Common/TextEditor";
import { uploadImage } from "src/components/Common/Utils";
import { BlogModalProps } from "./SubCategory";
import CustomButton from "src/components/CustomButton";
import { useMutation } from "@apollo/client";
import { ADD_BLOG, UPDATE_BLOG } from "src/graphql/mutations";

const BlogModal: React.FC<BlogModalProps> = ({
  open,
  handleClose,
  newBlog,
  setNewBlog,
  isEdit = false,
}) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(
    newBlog?.image || null
  );

  const [AddBlog] = useMutation(ADD_BLOG);
  const [UpdateBlog] = useMutation(UPDATE_BLOG);

  useEffect(() => {
    if (!open) {
      setImageFile(null);
      setImageUrl(null);
    } else if (newBlog?.image) {
      setImageUrl(newBlog.image);
    }
  }, [open, newBlog]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
      setImageFile(file);
      setImageUrl(URL.createObjectURL(file)); // Create a preview URL for the image
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    publishedDate: Yup.date().required("Published date is required"),
    publishedBy: Yup.string().required("Published by is required"),
    description: Yup.string()
      .required("Description is required")
      .min(10, "Description should be at least 10 characters long"),
  });

  const formik = useFormik({
    initialValues: {
      title: newBlog?.title || "",
      publishedDate: newBlog?.publishedDate || "",
      publishedBy: newBlog?.publishedBy || "",
      description: newBlog?.description || "",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      try {
        let finalImageUrl = imageUrl;

        // Upload image if there is an image file
        if (imageFile) {
          const res = await uploadImage(imageFile);
          finalImageUrl = res?.uploadImage?.url;
        }

        // Extracting necessary values and setting the new blog state
        const { blogDescriptionReadingTime, blogTitleReadingTime, ...rest } =
          values;

        // Save or update blog
        const variables = {
          _id: isEdit ? newBlog._id : undefined,
          input: {
            ...rest,
            image: finalImageUrl,
            subCategoryId: newBlog.subCategoryId,
          },
        };

        const mutation = isEdit ? UpdateBlog : AddBlog;
        const res = await mutation({ variables });

        if (res.errors) {
          throw new Error("Internal Error");
        }

        toast.success(
          isEdit ? "Blog updated successfully!" : "Blog added successfully!"
        );

        // After saving, reset and close the modal
        setNewBlog({
          title: "",
          image: "",
          publishedDate: "",
          publishedBy: "",
          description: "",
          createdBy: "",
          subCategoryId: newBlog.subCategoryId,
          blogTitleReadingTime: null,
          blogDescriptionReadingTime: null,
        });
        handleClose();
      } catch (error: any) {
        toast.error(
          error.message || "An error occurred while saving the blog."
        );
        console.error("Error uploading image:", error);
      }
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h6" sx={{ color: "#00C5B9", textAlign: "center" }}>
          {isEdit ? "Update Blog Post" : "Create New Blog Post"}
        </Typography>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 3,
            borderRadius: "8px",
          }}
        >
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && (formik.errors.title as string)}
          />

          <Box
            sx={{
              borderRadius: 2,
              marginY: "10px",
              border: "2px dashed #00C5B9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              overflow: "hidden",
              height: "200px",
            }}
          >
            <input
              type="file"
              onChange={handleImageChange}
              style={{ display: "none" }}
              id="blog-image-upload"
            />
            <label
              htmlFor="blog-image-upload"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="blog"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <Typography color="primary" sx={{ cursor: "pointer" }}>
                  Upload Image
                </Typography>
              )}
            </label>
          </Box>

          <TextField
            fullWidth
            label="Published Date"
            variant="outlined"
            type="date"
            name="publishedDate"
            InputLabelProps={{ shrink: true }}
            value={formik.values.publishedDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.publishedDate &&
              Boolean(formik.errors.publishedDate)
            }
            helperText={
              formik.touched.publishedDate &&
              (formik.errors.publishedDate as string)
            }
          />

          <TextField
            fullWidth
            label="Published By"
            variant="outlined"
            name="publishedBy"
            value={formik.values.publishedBy}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.publishedBy && Boolean(formik.errors.publishedBy)
            }
            helperText={
              formik.touched.publishedBy &&
              (formik.errors.publishedBy as string)
            }
          />

          <RichTextEditor
            value={formik.values.description}
            onChange={(value) => formik.setFieldValue("description", value)}
          />
        </Box>

        <DialogActions sx={{ p: 3, justifyContent: "space-between" }}>
          <CustomButton onClick={handleClose} variant="outlined">
            Cancel
          </CustomButton>
          <CustomButton variant="contained" type="submit">
            {isEdit ? "Update" : "Save"}
          </CustomButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BlogModal;
