import React, { useEffect, useState, useCallback } from "react";
import { Box, TextField, Typography, Divider } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_BLOG } from "src/graphql/mutations";
import { GET_BLOGS } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteModel from "src/components/Common/DeleteModel";

interface Blog {
  _id?: string;
  publishedDate?: string;
  publishedBy?: string;
  description?: string;
  createdBy?: string;
}

const Blog: React.FC = () => {
  const [list, setList] = useState<Blog[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [total, setTotal] = useState(0);
  const [deleteBlog] = useMutation(DELETE_BLOG);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteBlogId, setDeleteBlogId] = useState<string | null>(null);

  const COLUMNS = [
    { headerName: "Published Date", field: "publishedDate", flex: 1 },
    { headerName: "Published By", field: "publishedBy", flex: 1 },
    { headerName: "Description", field: "description", flex: 1 },
    { headerName: "Created By", field: "createdBy", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <div>
          <IconButton
            onClick={() => {
              setDeleteBlogId(row._id);
              setOpenDeleteModel(true);
            }}
            aria-label="delete"
            style={{ color: "#00C5B9" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_BLOGS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getBlogs?.data) {
      setList(data.getBlogs.data);
    }
    setTotal(data?.getBlogs?.count);
  }, [data]);

  const handleDelete = async () => {
    if (!deleteBlogId) return;
    try {
      await deleteBlog({
        variables: { _id: deleteBlogId },
      });
      setOpenDeleteModel(false);
      refetch();
    } catch (error) {
      // Handle error appropriately
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Blog
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this blog?"
        />
      )}
    </Box>
  );
};

export default Blog;
