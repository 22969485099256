import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import moment from "moment";
import { channelIcons, NewsPageProps } from "./News";
import backButton from "../../Images/backButton.svg";

const NewsPage: React.FC<NewsPageProps> = ({ data, onClose }) => {
  const getIconForSourceType = (sourceType: any) => {
    const match = channelIcons?.find((item) => item?.sourceType === sourceType);
    return match ? match.icon : null;
  };
  const icon = getIconForSourceType(data?.sourceType);
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={onClose}
        />
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          {" "}
          News
        </Typography>
      </div>
      <Container
        maxWidth="md"
        sx={{ textAlign: "start", height: "98vh", overflowY: "scroll" }}
      >
        <Box sx={{ my: 4 }}>
          <Paper elevation={3} sx={{ borderRadius: 2 }}>
            <img
              src={data?.image || "https://via.placeholder.com/1200x500"}
              alt={data?.title || "News Image"}
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px 8px 0 0",
              }}
            />
            <Box sx={{ p: 4 }}>
              <Typography variant="h4" component="h1" gutterBottom>
                {data?.title}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  alignContent: "center",
                  placeItems: "center",
                }}
              >
                <Typography>
                  <img
                    src={icon || "https://via.placeholder.com/1200x500"}
                    alt={icon || "Source"}
                    style={{
                      height: "auto",
                    }}
                    width={80}
                  />
                </Typography>
                <Typography mx={4}>{data?.sourceType}</Typography>
              </Typography>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" paragraph>
                  {data?.shortDescription}
                </Typography>
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" paragraph>
                  {data?.longDescription}
                </Typography>
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="body1" color="textSecondary">
                  <strong>Date:</strong>{" "}
                  {moment(data?.date).format("MMMM D, YYYY")}
                  <br />
                  <strong>Clicks:</strong> {data?.clickCount ?? "N/A"}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
                <IconButton href="#" aria-label="Facebook">
                  <FacebookIcon />
                </IconButton>
                <IconButton href="#" aria-label="Instagram">
                  <InstagramIcon />
                </IconButton>
                <IconButton href="#" aria-label="LinkedIn">
                  <LinkedInIcon />
                </IconButton>
                <IconButton href="#" aria-label="Twitter">
                  <TwitterIcon />
                </IconButton>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default NewsPage;
