import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography, Divider } from "@mui/material";
import TopTenBusinessTable from "./TopTenBusinessTable";
import { useQuery } from "@apollo/client";
import { GET_SUB_CATEGORY_DATA } from "src/graphql/query";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import TopThreeBusinessTable from "./TopThreeBusinessTable";
import FaqTable from "./FaqTable";
import BlogTable from "./BlogTable";
import TagManagementTable from "./tagsTable";
import { TabData } from "./SubCategory";
import backButton from "../../Images/backButton.svg";
import { useParams, useNavigate } from "react-router-dom";

const SubcategoryTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const navigate = useNavigate();
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const { id } = useParams<{ id: string }>();

  const { loading, error, data, refetch } = useQuery(GET_SUB_CATEGORY_DATA, {
    variables: {
      subcategoryId: id,
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const handleBackButtonClick = () => {
    navigate(-1);
  };
  const topTenBusiness = data?.getRelatedDataBySubCategory?.topTenBusiness;
  const topThreeBusiness = data?.getRelatedDataBySubCategory?.topThreeBusiness;
  const faqData = data?.getRelatedDataBySubCategory?.faq;
  const tagData = data?.getRelatedDataBySubCategory?.tags;
  const tabData: TabData = {
    top10: (
      <div>
        <TopTenBusinessTable
          initialBusinesses={topTenBusiness}
          reload={refetch}
        />
      </div>
    ),
    top3: (
      <div>
        <TopThreeBusinessTable
          initialBusinesses={topThreeBusiness}
          reload={refetch}
        />
      </div>
    ),
    faq: (
      <div>
        <FaqTable initialFaqs={faqData} reload={refetch} />
      </div>
    ),
    blogs: (
      <div>
        <BlogTable />
      </div>
    ),
    tags: (
      <div>
        <TagManagementTable initialTags={tagData} reload={refetch} />
      </div>
    ),
  };

  const tabs: string[] = ["Top 10", "Top 3", "FAQ", "Blogs", "Tags"];

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;
  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          mb={2}
        >
          <img
            src={backButton}
            alt="backButton"
            height={25}
            width={25}
            style={{ marginRight: "12px", cursor: "pointer" }}
            onClick={handleBackButtonClick}
          />
          <Typography variant="h4" style={{ color: "#00C5B9" }}>
            {data?.getRelatedDataBySubCategory?.Data?.subCategoryName}
          </Typography>
        </Box>
        <Divider />
        <Box mt={0}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            aria-label="subcategory tabs"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            {tabs.map((label, index) => (
              <Tab
                key={index}
                label={label}
                sx={{
                  textTransform: "none",
                  minWidth: 100,
                  color: activeTab === index ? "#1976d2" : "#000",
                }}
              />
            ))}
          </Tabs>
        </Box>
      </Box>

      <Box
        sx={{
          padding: "20px",
          overflowY: "scroll",
          borderRadius: "8px",
        }}
      >
        {tabData[tabs[activeTab].toLowerCase().replace(/\s/g, "")]}
      </Box>
    </Box>
  );
};

export default SubcategoryTabs;
