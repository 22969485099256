// Image Imports
import tv9India from "src/Images/tv9India.png";
import divyaBhaskar from "src/Images/divyaBhaskar.png";
import gujuratSamachar from "src/Images/GujaratSamachar.png";
import gujaratMirror from "src/Images/gujaratMirror.jpg";
import chitralekha from "src/Images/ChitraLekha.png";
import abpAsmita from "src/Images/AbpAsmita.png";
import sandesh from "src/Images/Sandesh.png";
import news18 from "src/Images/News18.png";

// Channel Icons Mapping
export const channelIcons = [
  { sourceType: "TV9 GUJARATI", icon: tv9India },
  { sourceType: "DIVYA BHASKAR", icon: divyaBhaskar },
  { sourceType: "GUJARAT SAMACHAR", icon: gujuratSamachar },
  { sourceType: "GUJARAT MIRROR", icon: gujaratMirror },
  { sourceType: "CHITRALEKHA", icon: chitralekha },
  { sourceType: "ABP NEWS", icon: abpAsmita },
  { sourceType: "SANDESH", icon: sandesh },
  { sourceType: "NEWS18", icon: news18 },
];

// Enums for News Categories
export enum NewsCategory {
  All = "all",
  Astro = "astro",
  Bollywood = "bollywood",
  Business = "business",
  Climate = "climate",
  Crime = "crime",
  Entertainment = "entertainment",
  Fashion = "fashion",
  Gujarat = "gujarat",
  Health = "health",
  International = "international",
  IPL = "ipl",
  Lifestyle = "lifestyle",
  Magazine = "magazine",
  National = "national",
  Others = "others",
  Technology = "technology",
  Sports = "sports",
  Utility = "utility",
}

// Display Name Mappings
const newsCategoryDisplayNames: Record<NewsCategory, string> = {
  [NewsCategory.All]: "All",
  [NewsCategory.Astro]: "Astro",
  [NewsCategory.Bollywood]: "Bollywood",
  [NewsCategory.Business]: "Business",
  [NewsCategory.Climate]: "Climate",
  [NewsCategory.Crime]: "Crime",
  [NewsCategory.Entertainment]: "Entertainment",
  [NewsCategory.Fashion]: "Fashion",
  [NewsCategory.Gujarat]: "Gujarat",
  [NewsCategory.Health]: "Health",
  [NewsCategory.International]: "International",
  [NewsCategory.IPL]: "IPL",
  [NewsCategory.Lifestyle]: "Lifestyle",
  [NewsCategory.Magazine]: "Magazine",
  [NewsCategory.National]: "National",
  [NewsCategory.Others]: "Others",
  [NewsCategory.Technology]: "Technology",
  [NewsCategory.Sports]: "Sports",
  [NewsCategory.Utility]: "Utility",
};

// Options Generation
export const newsCategoryOptions = Object.keys(NewsCategory).map((key) => ({
  value: NewsCategory[key as keyof typeof NewsCategory],
  label:
    newsCategoryDisplayNames[NewsCategory[key as keyof typeof NewsCategory]],
}));

// Interface for News Types
export interface NewsTypes {
  _id: string;
  date: string;
  category: NewsCategory;
}

export interface NewsData {
  image?: string;
  title?: string;
  shortDescription?: string;
  longDescription?: string;
  date?: string;
  sourceType?: string;
  sourceId?: string;
  clickCount?: number;
}

export interface NewsPageProps {
  data: NewsData;
  onClose: () => void;
}
