import React from "react";
import {
  DataGrid,
  GridPaginationModel,
  GridRowSelectionModel,
  GridRowsProp,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    backgroundColor: "white", // Dark blue background for headers
    color: "#197BA0", // White text color for headers
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#197ba017", // Sky blue background on hover
  },
}));

interface CustomTableProps {
  columns: any;
  data: GridRowsProp;
  paginationModel: GridPaginationModel;
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  totalCount: number;
  onRowClick?: (id: string) => void;
}

const CustomTable: React.FC<CustomTableProps> = ({
  columns,
  data,
  totalCount,
  paginationModel,
  onPageChange,
  onPageSizeChange,
  onRowClick,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <CustomDataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row._id}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={(model) => {
          if (model.page !== undefined) {
            onPageChange(model.page);
          }
          if (model.pageSize !== undefined) {
            onPageSizeChange(model.pageSize);
          }
        }}
        pageSizeOptions={[5, 10, 25]}
        rowCount={totalCount}
        onRowClick={(params) => {
          if (onRowClick) {
            onRowClick(params.row._id);
          }
        }}
        checkboxSelection={false}
        rowSelectionModel={[] as GridRowSelectionModel}
      />
    </div>
  );
};

export default CustomTable;
