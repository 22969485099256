export enum PopularLocationCategory {
  Beach_Lake = "Beach_Lake",
  Garden_Park = "Garden_Park",
  HistoricalSite = "Historicalsite",
  Temple = "Temple",
  Malls = "Malls",
  Amusement_Theme_park = "Amusement_Theme_park",
  Museum = "Museum",
  water_front = "water_front",
  Library = "Library",
  game_zone = "game_zone",
  Theater = "Theater",
  Stadium_Auditorium = "Stadium_Auditorium",
  All = "All",
}

const popularLocationCategoryDisplayNames = {
  [PopularLocationCategory.Garden_Park]: "Garden & Park",
  [PopularLocationCategory.Beach_Lake]: "Beach & Lake",
  [PopularLocationCategory.HistoricalSite]: "Historical Site",
  [PopularLocationCategory.Amusement_Theme_park]: "Amusement & Theme Park",
  [PopularLocationCategory.water_front]: "Water Front",
  [PopularLocationCategory.Museum]: "Museum",
  [PopularLocationCategory.Theater]: "Theater",
  [PopularLocationCategory.Library]: "Library",
  [PopularLocationCategory.Stadium_Auditorium]: "Stadium & Auditorium",
  [PopularLocationCategory.Temple]: "Temple",
  [PopularLocationCategory.Malls]: "Malls",
  [PopularLocationCategory.game_zone]: "Game Zone",
  [PopularLocationCategory.All]: "All",
};

export const popularLocationCategoryOptions = Object.keys(
  PopularLocationCategory
).map((key) => ({
  value: PopularLocationCategory[key as keyof typeof PopularLocationCategory],
  label:
    popularLocationCategoryDisplayNames[
      PopularLocationCategory[key as keyof typeof PopularLocationCategory]
    ],
}));
