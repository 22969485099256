import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CustomButton from "../CustomButton";

interface DeleteModelProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title?: string;
  message?: string;
}

const DeleteModel: React.FC<DeleteModelProps> = ({
  open,
  onClose,
  onConfirm,
  title = "Confirm Deletion",
  message = "Are you sure you want to delete this item?",
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          borderRadius: theme.shape.borderRadius,
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary">
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={onClose} color="primary" variant="outlined">
          Cancel
        </CustomButton>
        <CustomButton
          onClick={onConfirm}
          variant="outlined"
          autoFocus
          style={{
            backgroundColor: theme.palette.error.main,
            color: "white",
          }}
        >
          Delete
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModel;
