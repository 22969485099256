import React, { forwardRef, lazy, Suspense, useCallback } from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./richTextEditor.module.css";
import cn from "classnames";

type RichTextEditorProps = ReactQuillProps & {
  className?: string;
  value?: string;
  onChange?: (content: string) => void;
};

export type RichTextEditorRef = ReactQuill;

const LazyReactQuill = lazy(() => import("react-quill"));

const toolbarOptions = [
  [{ size: [] }],
  ["bold", "underline"],
  [{ color: [] }, { background: [] }],
  [{ align: [] }], // Combines all alignments into one option
  ["link", "image"],
  ["code-block"],
  ["clean"],
];

const modules = {
  toolbar: toolbarOptions,
};

const formats = [
  "size",
  "bold",
  "underline",
  "color",
  "background",
  "align",
  "link",
  "image",
  "code-block",
];

const RichTextEditor = forwardRef<RichTextEditorRef, RichTextEditorProps>(
  ({ className, value, onChange, ...props }, ref) => {
    const handleChange = useCallback(
      (content: string) => {
        if (onChange) {
          onChange(content);
        }
      },
      [onChange]
    );

    return (
      <div className={cn(styles.richTextContainer)}>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyReactQuill
            ref={ref}
            value={value}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            className={cn(
              styles.richTextContainer,
              className,
              "border border-secondary-300"
            )}
            {...props}
          />
        </Suspense>
      </div>
    );
  }
);

RichTextEditor.displayName = "RichTextEditor";

export default RichTextEditor;
