// LocationList.tsx
import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import LocationCard from "./LocationCard";
import { PopularLocation } from "./types";

interface LocationListProps {
  locations: PopularLocation[];
}

const LocationList: React.FC<LocationListProps> = ({ locations }) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Popular Locations
      </Typography>
      <Grid container spacing={2}>
        {locations.map((location) => (
          <Grid item xs={12} sm={6} md={4} key={location._id}>
            <LocationCard location={location} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LocationList;
