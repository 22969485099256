import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import { GET_TAGS } from "src/graphql/query";
import { DELETE_TAG } from "src/graphql/mutations";
import DeleteModel from "src/components/Common/DeleteModel";
import { toast } from "react-toastify";

interface Tag {
  _id: string;
  key: string;
  value: string;
  subCategoryId: string | null;
}

const Tags: React.FC = () => {
  const [list, setList] = useState<Tag[]>([]);
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteTagId, setDeleteTagId] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [total, setTotal] = useState(0);
  const [deleteTag] = useMutation(DELETE_TAG);

  const COLUMNS = [
    { headerName: "Key", field: "key", flex: 1 },
    { headerName: "Value", field: "value", flex: 1 },
    { headerName: "SubCategory ID", field: "subCategoryId", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: Tag }) => (
        <div>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEdit(row)}
              aria-label="edit"
              style={{ marginRight: "8px", color: "#00C5B9" }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setDeleteTagId(row._id);
                setOpenDeleteModel(true);
              }}
              aria-label="delete"
              style={{ color: "#00C5B9" }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_TAGS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getTags?.data) {
      setList(data.getTags.data);
      setTotal(data.getTags.count);
    }
  }, [data]);

  const handleEdit = (rowData: Tag) => {
    setSelectedTag(rowData);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    if (!deleteTagId) return;
    try {
      const response = await deleteTag({
        variables: { _id: deleteTagId },
      });
      if (response.data?.deleteTag?.statusCode === 200) {
        toast.success(response.data.deleteTag.message);
      } else {
        toast.error("Failed to delete tag");
      }
      refetch();
      setOpenDeleteModel(false);
    } catch (error) {
      console.error("Error deleting tag:", error);
      toast.error("An error occurred while deleting the tag");
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteTagId(null);
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Tags
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box width={100}>
            <CustomButton
              onClick={() => {
                setSelectedTag(null);
                setOpenModal(true);
              }}
              variant="contained"
            >
              Create
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this tag?"
        />
      )}

      {/* {openModal && (
        <TagsModal
          open={openModal}
          handleClose={handleCloseModal}
          tag={selectedTag}
          refetchTags={refetch}
        />
      )} */}
    </Box>
  );
};

export default Tags;
