import React, { useState, FC, useEffect } from "react";
import {
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import Select from "react-select";
import CustomButton from "src/components/CustomButton";
import { useQuery } from "@apollo/client";
import { GET_CATEGORY_DATA, GET_TAGS } from "src/graphql/query";

interface Option {
  value: string;
  label: string;
  id?: string;
  isSelect?: boolean;
}

interface Field {
  order: number;
  id: string;
  display: string;
  type: "text-area" | "radio" | "checkbox" | "dropdown";
  options?: Option[];
  value?: string;
  isDynamic?: boolean;
}

interface FormComponentProps {
  subCategoryData: Field[];
  tagData: Option[];
  setFieldValue: (field: string, value: any) => void;
  jsonData?: any;
}

const FormComponent: FC<FormComponentProps> = ({
  subCategoryData,
  tagData,
  setFieldValue,
  jsonData,
}) => {
  const [formState, setFormState] = useState<any>({});

  // Effect to handle initial form data setup
  useEffect(() => {
    const initialState = subCategoryData.reduce((acc, field) => {
      const dynamicOption = jsonData?.dynamicOptions?.find(
        (item: any) => item?.display === field?.display
      );

      if (field.type === "checkbox") {
        acc[field.display] = dynamicOption
          ? dynamicOption.options
              .filter((option: Option) => option.isSelect)
              .map((option: Option) => option.value)
          : field.options
              ?.filter((option) => option.isSelect)
              .map((option) => option.value) || [];
      } else if (field.type === "dropdown" && dynamicOption) {
        acc[field.display] = dynamicOption.value || "";
      } else {
        acc[field.display] = jsonData
          ? jsonData[field.display] || field.value || ""
          : field.value ?? "";
      }
      return acc;
    }, {} as Record<string, any>);
    setFormState(initialState);
  }, [jsonData, subCategoryData]);

  const handleChange = (display: string, value: any) => {
    setFormState((prevState: any) => ({ ...prevState, [display]: value }));
  };

  const handleCheckboxChange = (
    display: string,
    value: string,
    checked: boolean
  ) => {
    setFormState((prevState: any) => {
      const currentValues = prevState[display] || [];
      return {
        ...prevState,
        [display]: checked
          ? [...currentValues, value]
          : currentValues.filter((v: any) => v !== value),
      };
    });
  };

  const renderField = (field: Field) => {
    const { display, type, options, id, isDynamic } = field;
    switch (type) {
      case "text-area":
        return (
          <FormControl key={id} fullWidth sx={{ marginY: "16px" }}>
            <TextField
              label={display}
              multiline
              rows={4}
              variant="outlined"
              value={formState[display] || ""}
              onChange={(e) => handleChange(display, e.target.value)}
            />
          </FormControl>
        );
      case "radio":
        return (
          <FormControl key={id} fullWidth sx={{ marginY: "16px" }}>
            <Typography
              fontSize={20}
              fontWeight={500}
              sx={{ alignSelf: "start", display: "flex" }}
            >
              {display}
            </Typography>
            <RadioGroup
              value={formState[display] || ""}
              onChange={(e) => handleChange(display, e.target.value)}
            >
              <Box
                display="grid"
                gridTemplateColumns="repeat(4, minmax(300px, auto))"
              >
                {options?.map((option: any, index) => (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={<Radio />}
                    label={option}
                    style={{ margin: "none" }}
                  />
                ))}
              </Box>
            </RadioGroup>
          </FormControl>
        );
      case "checkbox":
        return (
          <FormControl key={id} fullWidth sx={{ marginY: "16px" }}>
            <Typography
              fontSize={20}
              fontWeight={500}
              gutterBottom
              sx={{ alignSelf: "start", display: "flex" }}
            >
              {display}
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns="repeat(4, minmax(300px, auto))"
            >
              {(isDynamic ? tagData : options)?.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      value={option.value}
                      checked={
                        formState[display]?.includes(option?.value) === true
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        handleCheckboxChange(
                          display,
                          option.value,
                          e.target.checked
                        )
                      }
                    />
                  }
                  label={option.label}
                />
              ))}
            </Box>
          </FormControl>
        );

      case "dropdown":
        return (
          <FormControl key={id} sx={{ marginY: "16px" }} fullWidth>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography
                  fontSize={20}
                  fontWeight={500}
                  gutterBottom
                  sx={{ alignSelf: "start", display: "flex" }}
                >
                  {display}
                </Typography>

                <Select
                  value={
                    options?.find(
                      (option) => option.value === formState[display]
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    handleChange(display, (selectedOption as Option)?.value)
                  }
                  options={options}
                  placeholder="Select an option"
                />
              </Grid>
            </Grid>
          </FormControl>
        );
      default:
        return null;
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const processCheckboxOptions = (
      options: Option[],
      selectedValues: string[]
    ) => {
      return options.map((option) => ({
        ...option,
        isSelect: selectedValues.includes(option.value),
      }));
    };

    const processDropDownOptions = (
      options: Option[],
      selectedValue: string
    ) => {
      return options.map((option) => ({
        ...option,
        value: option.value === selectedValue ? selectedValue : null,
      }));
    };

    const filteredDynamicOptions = subCategoryData.map((field) => {
      const { display, type, options } = field;
      if (type === "checkbox" && options) {
        return {
          id: field.id,
          type: "checkbox",
          display,
          order: field.order || 0,
          options: processCheckboxOptions(options, formState[display] || []),
          isDynamic: field.isDynamic || false,
          isKey: null,
        };
      } else if (type === "dropdown" && options) {
        return {
          id: field.id,
          type: "dropdown",
          display,
          order: field.order || 0,
          options: processDropDownOptions(options, formState[display] || ""),
          isDynamic: field.isDynamic || false,
          isKey: null,
          value: formState[display] || null,
        };
      } else {
        return {
          id: field.id,
          type,
          display,
          order: field.order || 0,
          options,
          isDynamic: field.isDynamic || false,
          isKey: null,
          value: formState[display] || null,
        };
      }
    });

    const finalResult = {
      dynamicOptions: filteredDynamicOptions,
      about: formState["about"],
    };

    setFieldValue("overview", JSON.stringify(finalResult, null, 2));
  };

  return (
    <form>
      <Typography
        variant="h5"
        gutterBottom
        fontSize={26}
        fontWeight={600}
        sx={{ alignSelf: "start", display: "flex", color: " #197BA0" }}
      >
        Form Fields
      </Typography>
      {subCategoryData.map(renderField)}
      <Box mt={2}>
        <Box>
          <CustomButton
            type="button"
            onClick={handleSubmit}
            variant="outlined"
            style={{ width: "300px" }}
          >
            Submit overview
          </CustomButton>
        </Box>
      </Box>
    </form>
  );
};

const DynamicForm: FC<{
  categoryId: string;
  setFieldValue: any;
  jsonData?: any;
}> = ({ categoryId, setFieldValue, jsonData }) => {
  const { data } = useQuery(GET_CATEGORY_DATA, {
    variables: {
      _id: categoryId,
    },
  });
  const { data: tagData } = useQuery(GET_TAGS, {
    variables: {
      _id: categoryId,
    },
  });

  return (
    <div style={{ marginInline: "40px" }}>
      <FormComponent
        subCategoryData={data?.getCategory?.data?.overviews ?? []}
        tagData={tagData?.getTags?.data ?? []}
        setFieldValue={setFieldValue}
        jsonData={jsonData}
      />
    </div>
  );
};

export default DynamicForm;
