export enum Age {
  All = "All",
  English = "English",
  Gujarati = "Gujarati",
  Hindi = "Hindi",
  Marathi = "Marathi",
  Bengali = "Bengali",
  Kannada = "Kannada",
  Malayalam = "Malayalam",
  Tamil = "Tamil",
  Other = "Other",
}

export enum EventCategory {
  All = "All",
  ComedyShows = "ComedyShows",
  WorkShops = "WorkShops",
  Kids = "Kids",
  MusicShows_Concerts = "MusicShows_Concerts",
  Performances = "Performances",
  Flea_Exhibitions = "Flea_Exhibitions",
}

export enum EventPlatform {
  All = "All",
  Online = "Online",
  Offline = "Offline",
}

export enum DateFilter {
  All = "All",
  Today = "Today",
  Tomorrow = "Tomorrow",
  This_Weekend = "This_Weekend",
  This_Month = "This_Month",
}

export enum PriceFilter {
  All = "All",
  Free = "Free",
  Range0_500 = "Range0_500",
  Range501_1000 = "Range501_1000",
  Range1001_2000 = "Range1001_2000",
  RangeAbove2000 = "RangeAbove2000",
}

export enum VerificationStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Rejected = "REJECTED",
}

// Display Name Mappings
const ageDisplayNames = {
  [Age.All]: "All",
  [Age.English]: "English",
  [Age.Gujarati]: "Gujarati",
  [Age.Hindi]: "Hindi",
  [Age.Marathi]: "Marathi",
  [Age.Bengali]: "Bengali",
  [Age.Kannada]: "Kannada",
  [Age.Malayalam]: "Malayalam",
  [Age.Tamil]: "Tamil",
  [Age.Other]: "Other",
};

const eventCategoryDisplayNames = {
  [EventCategory.All]: "All",
  [EventCategory.ComedyShows]: "Comedy Shows",
  [EventCategory.WorkShops]: "Workshops",
  [EventCategory.Kids]: "Kids",
  [EventCategory.MusicShows_Concerts]: "Music Shows & Concerts",
  [EventCategory.Performances]: "Performances",
  [EventCategory.Flea_Exhibitions]: "Flea & Exhibitions",
};

const eventPlatformDisplayNames = {
  [EventPlatform.All]: "All",
  [EventPlatform.Online]: "Online",
  [EventPlatform.Offline]: "Offline",
};

const dateFilterDisplayNames = {
  [DateFilter.All]: "All",
  [DateFilter.Today]: "Today",
  [DateFilter.Tomorrow]: "Tomorrow",
  [DateFilter.This_Weekend]: "This Weekend",
  [DateFilter.This_Month]: "This Month",
};

const priceFilterDisplayNames = {
  [PriceFilter.All]: "All",
  [PriceFilter.Free]: "Free",
  [PriceFilter.Range0_500]: "₹0 - ₹500",
  [PriceFilter.Range501_1000]: "₹501 - ₹1000",
  [PriceFilter.Range1001_2000]: "₹1001 - ₹2000",
  [PriceFilter.RangeAbove2000]: "Above ₹2000",
};

const verificationStatusDisplayNames = {
  [VerificationStatus.Pending]: "Pending",
  [VerificationStatus.Approved]: "Approved",
  [VerificationStatus.Rejected]: "Rejected",
};

// Options Generation
export const ageOptions = Object.keys(Age).map((key) => ({
  value: Age[key as keyof typeof Age],
  label: ageDisplayNames[Age[key as keyof typeof Age]],
}));

export const eventCategoryOptions = Object.keys(EventCategory).map((key) => ({
  value: EventCategory[key as keyof typeof EventCategory],
  label:
    eventCategoryDisplayNames[EventCategory[key as keyof typeof EventCategory]],
}));

export const eventPlatformOptions = Object.keys(EventPlatform).map((key) => ({
  value: EventPlatform[key as keyof typeof EventPlatform],
  label:
    eventPlatformDisplayNames[EventPlatform[key as keyof typeof EventPlatform]],
}));

export const dateFilterOptions = Object.keys(DateFilter).map((key) => ({
  value: DateFilter[key as keyof typeof DateFilter],
  label: dateFilterDisplayNames[DateFilter[key as keyof typeof DateFilter]],
}));

export const priceFilterOptions = Object.keys(PriceFilter).map((key) => ({
  value: PriceFilter[key as keyof typeof PriceFilter],
  label: priceFilterDisplayNames[PriceFilter[key as keyof typeof PriceFilter]],
}));

export const verificationStatusOptions = Object.keys(VerificationStatus).map(
  (key) => ({
    value: VerificationStatus[key as keyof typeof VerificationStatus],
    label:
      verificationStatusDisplayNames[
        VerificationStatus[key as keyof typeof VerificationStatus]
      ],
  })
);

export interface EventData {
  image?: string;
  title?: string;
  venue?: string;
  startDate?: string;
  price?: string;
  eventBy?: string;
  description?: string;
}

export interface EventPageProps {
  data: EventData;
  onClose: () => void;
}
