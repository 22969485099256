import React from "react";
import {
  Box,
  Typography,
  Modal,
  Button,
  Divider,
  Avatar,
  Grid,
  Link,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface DealDetailModalProps {
  open: boolean;
  onClose: () => void;
  dealData: any;
}

const DealDetailModal: React.FC<DealDetailModalProps> = ({
  open,
  onClose,
  dealData,
}) => {
  if (!dealData) {
    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "90%",
            maxWidth: "lg",
            p: 3,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            transform: "translate(-50%, -50%)",
            overflowY: "auto",
            maxHeight: "80vh",
          }}
        >
          <Typography
            variant="h5"
            color="#00C5B9"
            textAlign="center"
            fontWeight="bold"
            mb={2}
          >
            Deal Details
          </Typography>
          <Typography>No deal data available.</Typography>
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Button onClick={onClose} variant="outlined" color="primary">
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  }

  const {
    businessProfile,
    title,
    category,
    subCategoryId,
    area,
    offer,
    offerType,
    description,
    termsAndConditions,
    duration,
    discountBracket,
    repeatDuration,
    image,
  } = dealData;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "90%",
          maxWidth: "lg",
          p: 3,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          transform: "translate(-50%, -50%)",
          overflowY: "auto",
          maxHeight: "80vh",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" color="#00C5B9" fontWeight="bold">
            Deal Details
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        {image && (
          <Box
            sx={{
              mb: 2,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={image}
              alt={title}
              style={{
                maxWidth: "100%",
                maxHeight: "300px",
                objectFit: "cover",
                borderRadius: "4px",
              }}
            />
          </Box>
        )}

        <Box>
          {/* Business Profile Section */}
          <Box display="flex" alignItems="center" mb={4}>
            <Avatar
              src={businessProfile?.brandLogo}
              alt={businessProfile?.brandName}
              sx={{ mr: 2 }}
            />
            <Box>
              <Typography variant="h6" fontWeight="bold">
                {businessProfile?.businessName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Managed by: {businessProfile?.UserName}
              </Typography>
              {businessProfile?.website && (
                <Typography variant="body2" color="textSecondary">
                  Website:{" "}
                  <Link
                    href={`https://${businessProfile?.website}`}
                    target="_blank"
                    rel="noopener"
                    color="primary"
                  >
                    {businessProfile?.website}
                  </Link>
                </Typography>
              )}
            </Box>
          </Box>

          <Divider />

          {/* Deal Information Section */}
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" fontWeight="bold">
                  Title:
                </Typography>
                <Typography variant="body2" mb={2}>
                  {title}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  Category:
                </Typography>
                <Typography variant="body2" mb={2}>
                  {category}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  Sub-Category:
                </Typography>
                <Typography variant="body2" mb={2}>
                  {subCategoryId}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  Area:
                </Typography>
                <Typography variant="body2" mb={2}>
                  {area}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" fontWeight="bold">
                  Offer:
                </Typography>
                <Typography variant="body2" mb={2}>
                  {offer} ({offerType})
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  Description:
                </Typography>
                <Typography variant="body2" mb={2}>
                  {description}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  Terms & Conditions:
                </Typography>
                <Typography variant="body2" mb={2}>
                  {termsAndConditions}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          {/* Additional Information Section */}
          <Box mt={3}>
            <Typography variant="body1" fontWeight="bold">
              Duration:
            </Typography>
            <Typography variant="body2" mb={2}>
              {new Date(duration?.startDate).toLocaleDateString()} -{" "}
              {new Date(duration?.endDate).toLocaleDateString()}
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Discount Bracket:
            </Typography>
            <Typography variant="body2" mb={2}>
              {discountBracket?.minimum}% - {discountBracket?.maximum}%
            </Typography>
            <Typography variant="body1" fontWeight="bold">
              Repeat Duration:
            </Typography>
            <Typography variant="body2" mb={2}>
              {repeatDuration?.repeat}
            </Typography>
          </Box>

          {/* Business Contact Section */}
          <Box mt={3}>
            <Typography variant="body1" fontWeight="bold">
              Business Contact:
            </Typography>
            <Typography variant="body2">
              Email: {businessProfile?.businessEmail} <br />
              Phone: {businessProfile?.mobileNumber}
            </Typography>
          </Box>

          <Box mt={3} display="flex" justifyContent="flex-end">
            <Button onClick={onClose} variant="outlined" color="primary">
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default DealDetailModal;
