import { TextField } from "@mui/material";
import classNames from "classnames";
import React from "react";
import styles from "./CustomTextfield.module.scss";

interface CustomTextFieldProps {
  label?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  variant?: "filled" | "outlined" | "standard";
  type?: string;
  placeholder?: string;
  fullWidth?: boolean;
}

const CustomTextField = ({
  label,
  value,
  onChange,
  className = "",
  variant = "outlined",
  type = "text",
  placeholder,
  fullWidth = false,
  ...props
}: CustomTextFieldProps) => {
  const combinedClassName = classNames(styles.textFieldStyle, className);

  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      className={combinedClassName}
      variant={variant}
      type={type}
      placeholder={placeholder}
      fullWidth={fullWidth}
      {...props}
    />
  );
};

export default CustomTextField;
