// ReviewCard.tsx
import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  Rating,
  Avatar,
  Divider,
} from "@mui/material";
import { Review } from "./types";

interface ReviewCardProps {
  review: Review;
}

const ReviewCard: React.FC<ReviewCardProps> = ({ review }) => {
  return (
    <Card sx={{ maxWidth: 345, margin: 2, boxShadow: 3 }}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar
            src={review.profileImage}
            alt={review.authorName}
            sx={{ mr: 2 }}
          />
          <Box>
            <Typography variant="h6">{review.authorName}</Typography>
            <Typography variant="body2" color="text.secondary">
              {review.authorPosition} | {review.city}
            </Typography>
          </Box>
        </Box>
        <Typography variant="h5" gutterBottom>
          {review.title}
        </Typography>
        <Rating name="read-only" value={review.ratings} readOnly />
        <Typography variant="body1" mt={1} mb={2}>
          {review.description}
        </Typography>
        {review.image.length > 0 && (
          <Box>
            {review.image.map((imgUrl, index) => (
              <CardMedia
                key={index}
                component="img"
                height="140"
                image={imgUrl}
                alt={`Review image ${index}`}
              />
            ))}
          </Box>
        )}
      </CardContent>
      <Divider />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          Created at: {new Date(review.createdAt).toLocaleDateString()}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ReviewCard;
