import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import styles from "./PrivateLayout.module.scss";
import Header from "./Header.tsx";
import SideBar from "./SideBar";
import { useNavigate } from "react-router";

interface Props {
  children: React.ReactNode;
}

const PrivateLayout: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
  });
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header />
      <SideBar />
      <div className={styles.childrenContainer}>{children}</div>
    </Box>
  );
};

export default PrivateLayout;
