import React, { useEffect, useState, useCallback } from "react";
import { Box, TextField, Typography, Divider } from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { DELETE_CATEGORY } from "src/graphql/mutations";
import { GET_CATEGORY, GET_CATEGORY_DATA } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import CategoryModal from "./CategoryModal";
import DeleteModel from "src/components/Common/DeleteModel";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ROUTES } from "src/constant";

export interface Category {
  categoryType?: string;
  description?: string;
  _id: string;
  categoryName: string;
  businessProfileCount: string;
  imageUrl?: string;
  overviews?: any;
}

const Category: React.FC = () => {
  const [list, setList] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [total, setTotal] = useState(0);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [deleteCategory] = useMutation(DELETE_CATEGORY);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState<string | null>(null);
  const navigate = useNavigate();

  const COLUMNS = [
    { headerName: "Category Name", field: "categoryName", flex: 1 },
    { headerName: "Sub Category Count", field: "subCategoryCount", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <div>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setIsEditMode(true);
              handleEdit(row?._id);
            }}
            aria-label="edit"
            style={{ marginRight: "8px", color: "#00C5B9" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation();
              setDeleteCategoryId(row._id);
              setOpenDeleteModel(true);
            }}
            aria-label="delete"
            style={{ color: "#00C5B9" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const { loading, error, data, refetch } = useQuery(GET_CATEGORY, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    if (data?.getCategories?.data) {
      setList(data.getCategories.data);
    }
    setTotal(data?.getCategories?.count);
  }, [data]);

  const [fetchCategoryData] = useLazyQuery(GET_CATEGORY_DATA);

  const handleEdit = async (rowData: any) => {
    const res = await fetchCategoryData({
      variables: {
        _id: rowData,
      },
    });
    setSelectedCategory(res.data?.getCategory?.data);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    if (!deleteCategoryId) return;
    try {
      const res = await deleteCategory({
        variables: { id: deleteCategoryId },
      });
      if (res?.errors) {
        throw new Error(res.errors as any);
      } else {
        setOpenDeleteModel(false);
        refetch();
        toast.success(res?.data?.deleteCategory?.message);
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedCategory(null);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handleRowClick = (id: string) => {
    navigate(`${ROUTES.CATEGORY}/${id}`);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Category
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box width={100}>
            <CustomButton
              onClick={() => setOpenModal(true)}
              variant="contained"
              className="width: 200px"
            >
              Create
            </CustomButton>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onRowClick={handleRowClick}
        />
      </Box>
      <CategoryModal
        open={openModal}
        handleClose={handleCloseModal}
        category={selectedCategory}
        refetchCategory={refetch}
        isEditMode={isEditMode}
      />
      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this category?"
        />
      )}
    </Box>
  );
};

export default Category;
