import { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  FormControl,
  Grid,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_BUSINESS_PROFILE,
  UPDATE_BUSINESS_PROFILE,
} from "src/graphql/mutations";
import CustomButton from "src/components/CustomButton";
import { toast } from "react-toastify";
import SubCategoryDropDown from "./SubCategoryDropDown";
import { Formik, Form, FieldArray } from "formik";
import PincodeSelectDropDown from "src/components/Common/PincodeSelectDropDown";
import { uploadImage, uploadImages } from "src/components/Common/Utils";
import AdditionalSubCategories from "./AdditionalSubCategories";
import Select from "react-select";
import { GET_ALL_SUBCATEGORIES } from "src/graphql/query";
import DynamicForm from "./DynamicForm";
import { Address, FormValues, options } from "./Business";
import TimingSelect from "src/components/Common/TimingSelect";
import deleteIcon from "src/Images/delete.svg";
import addIcon from "src/Images/Plus.svg";
interface Timing {
  day: string;
  startTime: string;
  endTime: string;
}
const BusinessModal = ({ onClose, business, refetch }: any) => {
  const [overviewJson, setOverviewJson] = useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<string>("");
  const [timings, setTimings] = useState<Timing[]>(
    business?.timings?.[0]?.timings || []
  );
  const [activeStep, setActiveStep] = useState(0);
  const [createBusinessProfile] = useMutation(CREATE_BUSINESS_PROFILE);
  const [updateBusinessProfile] = useMutation(UPDATE_BUSINESS_PROFILE);
  const [gallaryImages, setGallaryImages] = useState<any[]>(
    business?.galleries[0]?.image || []
  );
  const [draggingIndex, setDraggingIndex] = useState<any>(null);

  // Handle drag start
  const handleDragStart = (index: any) => {
    setDraggingIndex(index);
  };

  // Handle drag over
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (index: number) => {
    if (draggingIndex === null) return;

    const updatedImages = [...gallaryImages];
    const [movedImage] = updatedImages.splice(draggingIndex, 1);
    updatedImages.splice(index, 0, movedImage);
    // Revoke old URLs to avoid memory leaks
    updatedImages.forEach((image) => {
      if (image.imageUrl) {
        URL.revokeObjectURL(image.imageUrl);
      }
    });
    setGallaryImages(updatedImages);
    setDraggingIndex(null);
  };

  const initialValues: FormValues = {
    UserName: business?.UserName || "",
    businessName: business?.businessName || "",
    primarySubCategory: business?.primarySubCategory?._id || "",
    additionalSubCategories: business
      ? business?.additionalSubCategories?.map(
          (subCategory: any) => subCategory._id
        ) || []
      : [],
    brandName: business?.brandName || "",
    brandLogo: business?.brandLogo || "",
    website: business?.website || "",
    businessEmail: business?.businessEmail || "",
    documents: [],
    managedBy: business?.managedBy || "",
    mobileNumber: business?.mobileNumber || "",
    bgImage: business?.bgImage || "",
    businessType:
      options.find((option) => option.value === business?.businessType) || "",
    owners: business?.user
      ? {
          firstName: business?.user?.firstName,
          lastName: business?.user?.lastName,
          mobileNumber: business?.user?.mobileNumber,
          email: business?.user?.email,
        }
      : {
          firstName: "",
          lastName: "",
          mobileNumber: "",
          email: "",
        },
    addresses: business?.address?.addressses ?? [
      {
        address: "",
        buildingOrShopNumber: "",
        landmark: "",
        neighborhood: "",
        googleMapLink: "",
        pinCode: "",
      },
    ],
    gallery: business?.galleries || { image: "", video: "" },
    timing: business?.timings?.[0]?.timings || [
      {
        day: "",
        startTime: "",
        endTime: "",
      },
    ],
    overview: null,
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleImageChange = async (
    field: string,
    file: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (file) {
      try {
        const data = await uploadImage(file);
        const imageUrl = data?.uploadImage?.url;
        if (imageUrl) {
          setFieldValue(field, imageUrl);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    } else {
      setFieldValue(field, null);
    }
  };

  const handleSubmit = async (values: FormValues) => {
    try {
      const filesToUpload = gallaryImages.map((banner) => {
        const fileUrl = banner?.imageUrl;
        // Convert the blob URLs to File objects if necessary
        if (fileUrl?.includes("blob:")) {
          return fetch(fileUrl)
            .then((res) => res.blob())
            .then(
              (blob) => new File([blob], "uploaded_image", { type: blob.type })
            );
        }
        return null;
      });

      const resolvedFiles = await Promise.all(filesToUpload);

      // Filter out any null values and only keep valid files
      const filesToUploadFiltered = resolvedFiles.filter(Boolean) as File[];

      // Use the new uploadImages function to upload files in bulk
      const uploadedImages = filesToUploadFiltered.length
        ? await uploadImages(filesToUploadFiltered)
        : gallaryImages;

      // Extract URLs from uploaded images
      const uploadedUrls = uploadedImages.map(
        (image: { url: string }) => image.url
      );

      // Update gallery images with the new URLs
      const updatedBannerImages = gallaryImages?.map(
        (banner, index) => uploadedUrls[index] || banner.url
      );

      const overview =
        typeof values?.overview === "string"
          ? JSON.parse(values?.overview)
          : values?.overview;

      const variables = {
        input: {
          ...values,
          timing: timings,
          overview,
          documents: [],
          gallery: { image: updatedBannerImages },
        },
      };

      const { primarySubCategory, ...rest } = variables?.input;

      const res = business
        ? await updateBusinessProfile({
            variables: {
              _id: business?._id,
              input: rest,
            },
          })
        : await createBusinessProfile({ variables });

      if (res?.data?.createBusinessProfile) {
        toast.success(
          res?.data?.createBusinessProfile?.message ||
            "Business Profile created successfully"
        );
        refetch();
        onClose();
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const { data } = useQuery(GET_ALL_SUBCATEGORIES, {
    notifyOnNetworkStatusChange: true,
  });
  const subCategoryData = data?.getAllSubCategories?.data;

  const handleTimingChange = (
    index: number,
    field: keyof any,
    value: string
  ) => {
    const updatedTimings = [...timings];
    updatedTimings[index] = { ...updatedTimings[index], [field]: value };
    setTimings(updatedTimings);
  };

  const addNewTimingField = () => {
    if (timings.length < 7) {
      setTimings([...timings, { day: "", startTime: "", endTime: "" }]);
    }
  };
  const removeTimingField = (index: number) => {
    const updatedTimings = timings.filter((_, i) => i !== index);
    setTimings(updatedTimings);
  };
  const usedDays = timings.map((timing) => timing.day);

  const handleAddBannerImage = () => {
    setGallaryImages([...gallaryImages, {}]);
  };

  const handleRemoveBannerImage = (index: number) => {
    const updatedBannerImages = [...gallaryImages];
    updatedBannerImages.splice(index, 1);
    setGallaryImages(updatedBannerImages);
  };
  //Banner Image function
  const handleBannerImageChange = (index: number, file: File | null) => {
    const updatedBannerImages = [...gallaryImages];
    const imageUrl = file
      ? URL.createObjectURL(file)
      : updatedBannerImages[index] || "";
    updatedBannerImages[index] = { imageUrl };
    setGallaryImages(updatedBannerImages);
  };

  const steps = [
    "Basic Information",
    "Address Details",
    "Business Timings",
    "Gallery & Media",
  ];
  return (
    <Box>
      <Box p={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="h4" style={{ color: "#00C5B9" }}>
            Create Business Profile
          </Typography>
        </Box>
        <Divider />
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ marginY: "12px" }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, setFieldValue }: any) => (
            <Form>
              {/* Step 1: Business Basic Information */}
              {/* Business Name */}

              {activeStep === 0 && (
                <Card>
                  <CardContent>
                    <Typography
                      fontSize={24}
                      fontWeight={600}
                      gutterBottom
                      sx={{ alignSelf: "start", display: "flex" }}
                      marginX={1}
                    >
                      Basic Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Business Name"
                          fullWidth
                          name="businessName"
                          value={values.businessName}
                          onChange={handleChange}
                          error={Boolean(
                            touched.businessName && errors.businessName
                          )}
                          helperText={
                            touched.businessName && errors.businessName
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Unique Name"
                          fullWidth
                          name="UserName"
                          value={values.UserName}
                          onChange={handleChange}
                          error={Boolean(touched.UserName && errors.UserName)}
                          helperText={touched.UserName && errors.UserName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <Typography
                            gutterBottom
                            fontWeight={500}
                            sx={{ alignSelf: "start", fontSize: 17 }}
                            marginX={1}
                          >
                            Primary Sub Category
                          </Typography>
                          <SubCategoryDropDown
                            handleSubCategoryChange={(value) =>
                              setFieldValue("primarySubCategory", value?.value)
                            }
                            selectedSubCategory={values.primarySubCategory}
                          />
                          {touched.primarySubCategory &&
                            errors.primarySubCategory && (
                              <Typography color="error">
                                {errors.primarySubCategory}
                              </Typography>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <AdditionalSubCategories
                            values={values}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            errors={errors}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Brand Name"
                          fullWidth
                          name="brandName"
                          value={values.brandName}
                          onChange={handleChange}
                          error={Boolean(touched.brandName && errors.brandName)}
                          helperText={touched.brandName && errors.brandName}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Website"
                          fullWidth
                          name="website"
                          value={values.website}
                          onChange={handleChange}
                          error={Boolean(touched.website && errors.website)}
                          helperText={touched.website && errors.website}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Business Email"
                          fullWidth
                          name="businessEmail"
                          value={values.businessEmail}
                          onChange={handleChange}
                          error={Boolean(
                            touched.businessEmail && errors.businessEmail
                          )}
                          helperText={
                            touched.businessEmail && errors.businessEmail
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          margin="dense"
                          label="Managed By"
                          fullWidth
                          name="managedBy"
                          value={values.managedBy}
                          onChange={handleChange}
                          error={Boolean(touched.managedBy && errors.managedBy)}
                          helperText={touched.managedBy && errors.managedBy}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          margin="dense"
                          label="Mobile Number"
                          fullWidth
                          name="mobileNumber"
                          value={values.mobileNumber}
                          onChange={handleChange}
                          error={Boolean(
                            touched.mobileNumber && errors.mobileNumber
                          )}
                          helperText={
                            touched.mobileNumber && errors.mobileNumber
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <Typography
                            variant="h6"
                            gutterBottom
                            fontWeight={500}
                            sx={{ alignSelf: "start", fontSize: 17 }}
                            marginX={1}
                          >
                            Business Type
                          </Typography>
                          <Select
                            value={
                              options.find(
                                (option) => option.value === values.businessType
                              ) || null
                            }
                            onChange={(option) =>
                              setFieldValue("businessType", option?.value)
                            }
                            options={options}
                            placeholder="Select Business Type"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mt={1}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            fontSize={24}
                            fontWeight={600}
                            sx={{ alignSelf: "start", display: "flex" }}
                            marginX={1}
                          >
                            Owners Details
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                margin="dense"
                                label="First Name"
                                fullWidth
                                name="owners.firstName"
                                value={values.owners.firstName}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.owners?.firstName &&
                                    errors.owners?.firstName
                                )}
                                helperText={
                                  touched.owners?.firstName &&
                                  errors.owners?.firstName
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                margin="dense"
                                label="Last Name"
                                fullWidth
                                name="owners.lastName"
                                value={values.owners.lastName}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.owners?.lastName &&
                                    errors.owners?.lastName
                                )}
                                helperText={
                                  touched.owners?.lastName &&
                                  errors.owners?.lastName
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                margin="dense"
                                label="Mobile Number"
                                fullWidth
                                name="owners.mobileNumber"
                                value={values.owners.mobileNumber}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.owners?.mobileNumber &&
                                    errors.owners?.mobileNumber
                                )}
                                helperText={
                                  touched.owners?.mobileNumber &&
                                  errors.owners?.mobileNumber
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                margin="dense"
                                label="Email"
                                fullWidth
                                name="owners.email"
                                value={values.owners.email}
                                onChange={handleChange}
                                error={Boolean(
                                  touched.owners?.email && errors.owners?.email
                                )}
                                helperText={
                                  touched.owners?.email && errors.owners?.email
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="end" mt={2}>
                      <Box marginX={2}>
                        <CustomButton variant="outlined" onClick={onClose}>
                          Cancel
                        </CustomButton>
                      </Box>
                      <Box marginX={2}>
                        <CustomButton variant="contained" onClick={handleNext}>
                          Next
                        </CustomButton>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              )}
              {/* after this will going on step 2 */}
              {/* Addresses */}
              {activeStep === 1 && (
                <Card>
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      fontSize={24}
                      fontWeight={600}
                      sx={{ alignSelf: "start", display: "flex" }}
                      marginX={1}
                    >
                      Address Details
                    </Typography>

                    <Box mb={2}>
                      <FieldArray name="addresses">
                        {({ insert, remove, push }) => (
                          <div>
                            <Box
                              display={"flex"}
                              justifyContent={"flex-end"}
                              alignSelf={"self-end"}
                            >
                              <CustomButton
                                type="button"
                                variant="outlined"
                                style={{
                                  display: "flex",

                                  width: 200,
                                }}
                                onClick={() =>
                                  push({
                                    address: "",
                                    buildingOrShopNumber: "",
                                    landmark: "",
                                    neighborhood: "",
                                    googleMapLink: "",
                                    pinCode: "",
                                  })
                                }
                              >
                                <Box
                                  gap={1}
                                  display={"flex"}
                                  alignItems={"center"}
                                >
                                  <img
                                    src={addIcon}
                                    alt="add"
                                    height={20}
                                    width={20}
                                  ></img>
                                  <span
                                    style={{
                                      marginTop: "4px",
                                      fontSize: "14px",
                                      lineHeight: "24px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Add Address
                                  </span>
                                </Box>
                              </CustomButton>
                            </Box>
                            {values.addresses.length > 0 &&
                              values.addresses.map(
                                (address: any, index: number) => (
                                  <Grid
                                    container
                                    spacing={2}
                                    key={index}
                                    mb={2}
                                  >
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        margin="dense"
                                        label="Address"
                                        fullWidth
                                        name={`addresses.${index}.address`}
                                        value={address.address || ""}
                                        onChange={handleChange}
                                        error={Boolean(
                                          touched.addresses?.[index]?.address &&
                                            errors.addresses?.[index]?.address
                                        )}
                                        helperText={
                                          touched.addresses?.[index]?.address &&
                                          errors.addresses?.[index]?.address
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        margin="dense"
                                        label="Building/Shop Number"
                                        fullWidth
                                        name={`addresses.${index}.buildingOrShopNumber`}
                                        value={
                                          address.buildingOrShopNumber || ""
                                        }
                                        onChange={handleChange}
                                        error={Boolean(
                                          touched.addresses?.[index]
                                            ?.buildingOrShopNumber &&
                                            errors.addresses?.[index]
                                              ?.buildingOrShopNumber
                                        )}
                                        helperText={
                                          touched.addresses?.[index]
                                            ?.buildingOrShopNumber &&
                                          errors.addresses?.[index]
                                            ?.buildingOrShopNumber
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        margin="dense"
                                        label="Landmark"
                                        fullWidth
                                        name={`addresses.${index}.landmark`}
                                        value={address.landmark || ""}
                                        onChange={handleChange}
                                        error={Boolean(
                                          touched.addresses?.[index]
                                            ?.landmark &&
                                            errors.addresses?.[index]?.landmark
                                        )}
                                        helperText={
                                          touched.addresses?.[index]
                                            ?.landmark &&
                                          errors.addresses?.[index]?.landmark
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        margin="dense"
                                        label="Neighborhood"
                                        fullWidth
                                        name={`addresses.${index}.neighborhood`}
                                        value={address.neighborhood || ""}
                                        onChange={handleChange}
                                        error={Boolean(
                                          touched.addresses?.[index]
                                            ?.neighborhood &&
                                            errors.addresses?.[index]
                                              ?.neighborhood
                                        )}
                                        helperText={
                                          touched.addresses?.[index]
                                            ?.neighborhood &&
                                          errors.addresses?.[index]
                                            ?.neighborhood
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <TextField
                                        margin="dense"
                                        label="Google Map Link"
                                        fullWidth
                                        name={`addresses.${index}.googleMapLink`}
                                        value={address.googleMapLink || ""}
                                        onChange={handleChange}
                                        error={Boolean(
                                          touched.addresses?.[index]
                                            ?.googleMapLink &&
                                            errors.addresses?.[index]
                                              ?.googleMapLink
                                        )}
                                        helperText={
                                          touched.addresses?.[index]
                                            ?.googleMapLink &&
                                          errors.addresses?.[index]
                                            ?.googleMapLink
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Box my={2}>
                                        <PincodeSelectDropDown
                                          handlePincodeChange={(data) =>
                                            setFieldValue(
                                              `addresses.${index}.pinCode`,
                                              data?.value
                                            )
                                          }
                                          selectedPincode={
                                            address.pinCode ?? ""
                                          }
                                        />
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Box my={2}>
                                        <CustomButton
                                          type="button"
                                          variant="outlined"
                                          onClick={() => remove(index)}
                                        >
                                          <Box
                                            gap={1}
                                            display={"flex"}
                                            alignItems={"center"}
                                          >
                                            <img
                                              src={deleteIcon}
                                              alt="deleteIcon"
                                              height={20}
                                              width={20}
                                            ></img>
                                            <span
                                              style={{
                                                marginTop: "2px",
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                fontWeight: 400,
                                              }}
                                            >
                                              {" "}
                                              Remove Address
                                            </span>
                                          </Box>
                                        </CustomButton>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                )
                              )}
                          </div>
                        )}
                      </FieldArray>
                    </Box>
                    <Box display="flex" justifyContent="end" mt={2}>
                      <Box marginX={2}>
                        <CustomButton onClick={handleBack} variant="outlined">
                          Back
                        </CustomButton>
                      </Box>
                      <Box marginX={2}>
                        <CustomButton
                          variant="contained"
                          onClick={() => {
                            setOverviewJson(true);
                            const res = subCategoryData.find((data: any) => {
                              return data?._id === values?.primarySubCategory;
                            });
                            setCategoryId(res?.categoryId[0] ?? "");
                            handleNext();
                          }}
                        >
                          Next
                        </CustomButton>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              )}

              {/* Timings Management */}
              {activeStep === 2 && (
                <Card>
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      fontSize={26}
                      fontWeight={600}
                      marginX={"40px"}
                      sx={{
                        alignSelf: "start",
                        display: "flex",
                        color: " #197BA0",
                      }}
                    >
                      Business Timings
                    </Typography>
                    <Box sx={{ marginTop: "20px", marginX: "40px" }}>
                      <Box
                        display={"flex"}
                        justifyContent={"flex-end"}
                        alignSelf={"self-end"}
                      >
                        <CustomButton
                          type="button"
                          variant="outlined"
                          style={{
                            display: "flex",

                            width: 200,
                          }}
                          onClick={addNewTimingField}
                        >
                          <Box gap={1} display={"flex"} alignItems={"center"}>
                            <img
                              src={addIcon}
                              alt="add"
                              height={20}
                              width={20}
                            ></img>
                            <span
                              style={{
                                marginTop: "4px",
                                fontSize: "14px",
                                lineHeight: "24px",
                                fontWeight: 400,
                              }}
                            >
                              Add Timing
                            </span>
                          </Box>
                        </CustomButton>
                      </Box>
                      {timings.map((timing, index) => (
                        <Box key={index} mt={2}>
                          <TimingSelect
                            key={index}
                            timings={timings}
                            onTimingChange={handleTimingChange}
                            onRemove={removeTimingField}
                            availableDays={usedDays}
                            index={index}
                            otherShown={false}
                          />
                          {errors.timings && (
                            <Typography
                              variant="caption"
                              color="error"
                              mx={1.5}
                            >
                              {errors.timings}
                            </Typography>
                          )}
                        </Box>
                      ))}
                    </Box>
                    {/* Overview */}
                    {overviewJson && (
                      <Box mt={4}>
                        <DynamicForm
                          jsonData={business ? business?.overviews[0] : null}
                          categoryId={categoryId}
                          setFieldValue={setFieldValue}
                        />
                      </Box>
                    )}
                    <Box display="flex" justifyContent="end" mt={2}>
                      <Box marginX={2}>
                        <CustomButton onClick={handleBack} variant="outlined">
                          Back
                        </CustomButton>
                      </Box>
                      <Box marginX={2}>
                        <CustomButton variant="contained" onClick={handleNext}>
                          Next
                        </CustomButton>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              )}

              {/* gallery and image section */}
              {activeStep === 3 && (
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Gallery & Media
                    </Typography>

                    {/* Brand Logo & Background Image */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1" gutterBottom>
                          Brand Logo
                        </Typography>
                        <Box my={1}>
                          <CustomButton
                            variant="outlined"
                            onClick={() =>
                              document.getElementById("brandLogo")?.click()
                            }
                          >
                            Upload Brand Logo
                          </CustomButton>
                        </Box>
                        <input
                          id="brandLogo"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleImageChange(
                              "brandLogo",
                              e.target.files?.[0],
                              setFieldValue
                            )
                          }
                        />
                        {values.brandLogo && (
                          <img
                            src={values.brandLogo}
                            alt="Brand Logo"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100px",
                              marginTop: "10px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography variant="body1" gutterBottom>
                          Background Image
                        </Typography>
                        <Box my={1}>
                          <CustomButton
                            variant="outlined"
                            onClick={() =>
                              document.getElementById("bgImage")?.click()
                            }
                          >
                            Upload Background Image
                          </CustomButton>
                        </Box>
                        <input
                          id="bgImage"
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            handleImageChange(
                              "bgImage",
                              e.target.files?.[0],
                              setFieldValue
                            )
                          }
                        />
                        {values.bgImage && (
                          <img
                            src={values.bgImage}
                            alt="Background"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100px",
                              marginTop: "10px",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>

                    {/* Gallery Images Section */}
                    <Divider style={{ margin: "16px 0" }} />
                    <Typography variant="h6" gutterBottom>
                      Gallery Images
                    </Typography>
                    <Grid container spacing={2}>
                      {gallaryImages?.map((banner, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={index}
                          draggable
                          onDragStart={() => handleDragStart(index)}
                          onDragOver={handleDragOver}
                          onDrop={() => handleDrop(index)}
                        >
                          <input
                            type="file"
                            onChange={(e) =>
                              handleBannerImageChange(
                                index,
                                e.target.files?.[0] || null
                              )
                            }
                            style={{ display: "none" }}
                            id={`banner-upload-${index}`}
                          />
                          <label htmlFor={`banner-upload-${index}`}>
                            <Box
                              sx={{
                                width: "100%",
                                height: 200,
                                borderRadius: 2,
                                border: "2px dashed #00C5B9",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                overflow: "hidden",
                              }}
                            >
                              {banner ? (
                                <img
                                  src={banner?.imageUrl || banner}
                                  alt={`banner-${index}`}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <Typography color="primary">
                                  Upload Image
                                </Typography>
                              )}
                            </Box>
                          </label>
                          <Box mt={1}>
                            <CustomButton
                              variant="outlined"
                              color="secondary"
                              onClick={() => handleRemoveBannerImage(index)}
                              style={{ width: "100%" }}
                            >
                              Remove Image
                            </CustomButton>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>

                    <Box mt={4}>
                      <CustomButton
                        variant="outlined"
                        onClick={handleAddBannerImage}
                      >
                        Add Gallery Image
                      </CustomButton>
                    </Box>

                    <Box display="flex" justifyContent="end" mt={2}>
                      <Box marginX={2}>
                        <CustomButton onClick={handleBack} variant="outlined">
                          Back
                        </CustomButton>
                      </Box>
                      <Box marginX={2}>
                        <CustomButton
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit as any}
                        >
                          Submit
                        </CustomButton>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default BusinessModal;
