import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Drawer,
} from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { DELETE_DEAL } from "src/graphql/mutations";
import { GET_DEAL, GET_DEALS } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "react-select";
import {
  DealCategory,
  FilterDeals,
  dealCategoryOptions,
  dealFilterOptions,
  DealTypes,
} from "./dealCategory";
import CustomButton from "src/components/CustomButton";
import CreateDeal from "./CreateDeal";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModel from "src/components/Common/DeleteModel";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DealDetailModal from "./DealDetailModal";

const Deal: React.FC = () => {
  const [list, setList] = useState<DealTypes[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<
    DealCategory | "all"
  >("all");
  const [filterOption, setFilterOption] = useState<FilterDeals | "all">("all");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openViewModal, setOpenViewModal] = useState<boolean>(false);
  const [total, setTotal] = useState(0);
  const [DeleteDeals] = useMutation(DELETE_DEAL);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [dealData, setDealData] = useState<any>();
  const [deleteDealId, setDeleteDealId] = useState<string | null>(null);
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);

  const COLUMNS = [
    { headerName: "Title", field: "title", flex: 1 },
    { headerName: "Category", field: "category", flex: 1 },
    { headerName: "Area", field: "area", flex: 1 },
    { headerName: "Offer", field: "offer", flex: 1 },
    { headerName: "Offer Type", field: "offerType", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: { _id: string } }) => (
        <div>
          <IconButton
            onClick={() => handleView(row)}
            aria-label="view"
            style={{ color: "#00C5B9" }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleEdit(row?._id);
            }}
            aria-label="edit"
            style={{ marginRight: "8px", color: "#00C5B9" }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleOpenDeleteModel(row._id)}
            aria-label="delete"
            style={{ color: "#00C5B9" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  const handleView = (data: any) => {
    GetDeal({
      variables: {
        _id: data?._id,
      },
    })
      .then((res) => {
        setDealData(res?.data?.getDeal?.data);
        setOpenViewModal(true);
      })
      .catch((err) => {
        console.error("Error fetching category data:", err);
      });
  };
  const { loading, error, data, refetch } = useQuery(GET_DEALS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
      selectedCategory:
        selectedCategory === "all" ? undefined : selectedCategory,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [GetDeal] = useLazyQuery(GET_DEAL);
  const handleEdit = (data: any) => {
    GetDeal({
      variables: {
        _id: data,
      },
    })
      .then((res) => {
        setDealData(res?.data?.getDeal?.data);
        setOpenModal(true);
      })
      .catch((err) => {
        console.error("Error fetching category data:", err);
      });
  };

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
        category: selectedCategory === "all" ? undefined : selectedCategory,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize, selectedCategory]
  );

  useEffect(() => {
    if (data?.getDeals?.data) {
      setList(data.getDeals.data);
    }
    setTotal(data?.getDeals?.totalDealsCount);
  }, [data]);

  const handleOpenDeleteModel = (dealId: string) => {
    setDeleteDealId(dealId);
    setOpenDeleteModel(true);
  };

  const handleDelete = async () => {
    if (!deleteDealId) return;
    try {
      await DeleteDeals({
        variables: { _id: deleteDealId },
      });
      refetch();
    } catch (error: any) {
      console.error("Failed to delete deal", error);
    } finally {
      setOpenDeleteModel(false);
      setDeleteDealId(null);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handleCategoryChange = (selectedOption: any) => {
    const value = selectedOption ? selectedOption.value : "all";
    setSelectedCategory(value as DealCategory | "all");
  };

  const handleFilterChange = (selectedOption: any) => {
    const value = selectedOption ? selectedOption.value : "all";
    setFilterOption(value as FilterDeals | "all");
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: pagination.pageSize,
      filter: value === "all" ? undefined : value,
    });
  };

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
  };

  if (loading) return <Loader />;
  if (error) return <ErrorComponent />;

  return openModal ? (
    <CreateDeal
      reload={() => refetch()}
      dealData={dealData}
      onClose={() => setOpenModal(false)}
    />
  ) : (
    <Box p={2}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          Deals
        </Typography>
        <Box display="flex" alignItems="center" gap={2}>
          <Box width={200}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Box>
          <Box width={100}>
            <CustomButton
              onClick={() => setOpenModal(true)}
              variant="contained"
            >
              Create
            </CustomButton>
          </Box>
          <IconButton onClick={() => setOpenDrawer(true)} aria-label="menu">
            <MenuIcon />
          </IconButton>
          {/* Category Drawer */}
        </Box>
      </Box>
      <Divider />
      {/* Filter Drawer */}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        sx={{ width: 250, flexShrink: 0 }}
      >
        <Box p={2} width={250} mt={10}>
          <Typography variant="h4" gutterBottom style={{ color: "#00C5B9" }}>
            Filters
          </Typography>
          <Box my={4}>
            <Typography variant="h6" gutterBottom>
              Category
            </Typography>
            <Select
              options={dealCategoryOptions}
              value={dealCategoryOptions.find(
                (option) => option.value === selectedCategory
              )}
              isClearable
              onChange={handleCategoryChange}
              placeholder="Filter by Category"
            />
          </Box>

          <Box my={4}>
            <Typography variant="h6" gutterBottom>
              Filter
            </Typography>
            <Select
              value={dealFilterOptions.find(
                (option) => option.value === filterOption
              )}
              onChange={handleFilterChange}
              options={dealFilterOptions}
              isClearable
              placeholder="Select Filter"
            />
          </Box>
        </Box>
      </Drawer>
      <Box mt={2}>
        <CustomTable
          columns={COLUMNS}
          data={list}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.pageSize,
          }}
          totalCount={total}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={() => setOpenDeleteModel(false)}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this deal?"
        />
      )}

      {openViewModal && (
        <DealDetailModal
          dealData={dealData}
          onClose={() => setOpenViewModal(false)}
          open={openViewModal}
        />
      )}
    </Box>
  );
};

export default Deal;
