import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Typography,
  FormControl,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { CREATE_EVENTS, UPDATE_EVENTS } from "src/graphql/mutations";
import CustomButton from "src/components/CustomButton";
import Select from "react-select";
import { toast } from "react-toastify";
import { eventCategoryOptions, eventPlatformOptions } from "./Events";
import AddressInput from "src/components/Common/AddressInput";
import { uploadImage } from "src/components/Common/Utils";
import moment from "moment";
import * as Yup from "yup";
import { useFormik } from "formik";
interface EventsModalProps {
  open: boolean;
  handleClose: () => void;
  events?: any; // Adjust type as necessary
  refetchEvents: () => void;
}

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  eventBy: Yup.string().required("Event Organizer is required"),
  startTime: Yup.string().required("Start Time is required"),
  endTime: Yup.string().required("End Time is required"),
  type: Yup.string().required("Type is required"),
  startDate: Yup.string().required("End Date is required"),
  endDate: Yup.string().required("End Date is required"),
  price: Yup.string().required("Price is required"),
  venue: Yup.string().required("Venue is required"),
  language: Yup.string().required("Language is required"),
  platform: Yup.string().required("Platform is required"),
  artist: Yup.string().required("Artist is required"),
  bookingPartner: Yup.string().required("Booking Partner is required"),
  address: Yup.lazy((value) => {
    return Yup.object().shape({
      address_1: Yup.string().when("platform", {
        is: (val: string) => val === "Offline",
        then: (schema) => schema.required("Address Line 1 is required"),
        otherwise: (schema) => schema.notRequired(),
      }),
    });
  }),
  image: Yup.string().required("Image is required."),
});

const EventsModal: React.FC<EventsModalProps> = ({
  open,
  handleClose,
  events,
  refetchEvents,
}) => {
  const [formValues, setFormValues] = useState<any>({
    title: "",
    image: "",
    eventBy: "",
    description: "",
    startTime: "",
    endTime: "",
    type: "",
    price: "",
    venue: "",
    startDate: "",
    endDate: "",
    language: "",
    platform: "",
    artist: "",
    address: {
      address_1: "",
      address_2: "",
      pinCode: "",
      location: "",
      landmark: "",
    },
    bookingPartner: "",
  });

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [errors, setErrors] = useState<any>({});
  const [AddEvent] = useMutation(CREATE_EVENTS);
  const [UpdateEvent] = useMutation(UPDATE_EVENTS);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => handleSubmit,
  });

  useEffect(() => {
    if (events) {
      setFormValues({
        title: events?.title || "",
        image: events?.image || "",
        eventBy: events?.eventBy || "",
        description: events?.description || "",
        startTime: events?.startTime || "",
        endTime: events?.endTime || "",
        type: events?.type || "",
        price: events?.price || "",
        venue: events?.venue || "",
        startDate: events?.startDate
          ? moment(events.startDate).format("YYYY-MM-DD")
          : "",
        endDate: events?.endDate
          ? moment(events.endDate).format("YYYY-MM-DD")
          : "",
        language: events?.language || "",
        platform: events?.platform || "",
        artist: events?.artist || "",
        address: events?.address[0] || "",
        bookingPartner: events?.bookingPartner || "",
      });
      setPreviewImage(events?.image || null);
    }
  }, [events]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    const { name, value } = e.target;
    setFormValues((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setImageFile(file);
    setPreviewImage(file ? URL.createObjectURL(file) : null);
  };

  const handleImageRemove = () => {
    setFormValues((prev: any) => ({ ...prev, image: "" }));
    setImageFile(null);
    setPreviewImage(null);
  };

  const handleSubmit = async () => {
    try {
      // Ensure that image URL is the original or uploaded version
      let imageUrl = formValues.image;

      // Upload image if there's an image file selected
      if (imageFile) {
        const response = await uploadImage(imageFile);
        if (response?.uploadImage?.url) {
          imageUrl = response.uploadImage.url;
        } else {
          throw new Error("Image upload failed.");
        }
      }

      // Remove '__typename' from the address if it exists
      const address = formValues.address as any;
      if (address && address.__typename) {
        const { __typename, ...addressWithoutTypename } = address;
        formValues.address = addressWithoutTypename;
      }

      // Prepare the input object for the API call
      const input = { ...formValues, image: imageUrl };
      if (input?.platform === "Online") {
        delete input.address;
      }
      // Validate the input
      await validationSchema.validate(input, { abortEarly: true });

      // Perform the API call for either updating or adding the event
      const response = events
        ? await UpdateEvent({ variables: { id: events._id, input } })
        : await AddEvent({ variables: { input } });
      // Check for response errors
      if (response.errors) {
        throw new Error(
          response.errors[0].message ||
            `${events ? "Update" : "Creation"} failed.`
        );
      }

      // Display success message
      toast.success(
        response.data[events ? "updateEvent" : "addEvent"].message ||
          `Event ${events ? "updated" : "created"} successfully!`
      );

      // Refetch events and close the modal
      refetchEvents();
      handleClose();
    } catch (error: any) {
      if (error.inner) {
        // Handle validation errors
        const validationErrors: Record<string, string> = {};
        error.inner.forEach((err: any) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        // Handle other errors
        toast.error(error?.message || "An error occurred. Please try again.");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Dialog open={open} onClose={handleClose} sx={{ marginTop: "40px" }}>
        <DialogTitle>{events ? "Update Event" : "Create Event"}</DialogTitle>
        <DialogContent>
          <TextField
            name="title"
            margin="dense"
            label="Title"
            fullWidth
            required
            value={formValues.title}
            onChange={handleInputChange}
            error={!!errors.title}
            helperText={errors.title}
          />
          <TextField
            name="description"
            margin="dense"
            label="Description"
            fullWidth
            value={formValues.description}
            onChange={handleInputChange}
            error={!!errors.description}
            helperText={errors.description}
          />
          <Box marginTop={2}>
            <TextField
              name="startDate"
              margin="dense"
              label="Start Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={formValues.startDate}
              onChange={handleInputChange}
              error={!!errors.startDate}
              helperText={errors.startDate}
            />
          </Box>
          <Box marginTop={2}>
            <TextField
              name="endDate"
              margin="dense"
              label="End Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={formValues.endDate}
              onChange={handleInputChange}
              error={!!errors.endDate}
              helperText={errors.endDate}
            />
          </Box>
          <Box marginTop={2}>
            <TextField
              name="startTime"
              margin="dense"
              label="Start Time"
              type="time"
              fullWidth
              value={formValues.startTime}
              onChange={handleInputChange}
              InputLabelProps={{
                shrink: true,
              }}
              error={!!errors.startTime}
              helperText={errors.startTime}
            />
          </Box>
          <Box marginTop={2}>
            <TextField
              name="endTime"
              margin="dense"
              label="End Time"
              type="time"
              fullWidth
              value={formValues.endTime}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleInputChange}
              error={!!errors.endTime}
              helperText={errors.endTime}
            />
          </Box>
          <Box my={2}>
            <Typography variant="h6" gutterBottom>
              Category
            </Typography>
            <FormControl fullWidth sx={{ zIndex: "9999" }}>
              <Select
                options={eventCategoryOptions}
                value={eventCategoryOptions.find(
                  (option) => option.value === formValues?.type
                )}
                maxMenuHeight={250}
                onChange={(data) =>
                  setFormValues((prev: any) => ({ ...prev, type: data?.value }))
                }
                placeholder="Filter by Category"
              />
              {!!errors.type && (
                <Typography variant="subtitle2" mt={1} mx={2} color={"#D32f2f"}>
                  {errors.type}
                </Typography>
              )}
            </FormControl>
          </Box>

          <TextField
            name="price"
            margin="dense"
            label="Price"
            fullWidth
            value={formValues.price}
            onChange={handleInputChange}
            error={!!errors.price}
            helperText={errors.price}
          />
          <TextField
            name="venue"
            margin="dense"
            label="Venue"
            fullWidth
            value={formValues.venue}
            onChange={handleInputChange}
            error={!!errors.venue}
            helperText={errors.venue}
          />
          <TextField
            name="language"
            margin="dense"
            label="Language"
            fullWidth
            value={formValues.language}
            onChange={handleInputChange}
            error={!!errors.language}
            helperText={errors.language}
          />
          <Box my={2}>
            <Typography variant="h6" gutterBottom>
              Platform
            </Typography>
            <FormControl fullWidth sx={{ zIndex: "9999" }}>
              <Select
                options={eventPlatformOptions}
                value={eventPlatformOptions.find(
                  (option) => option.value === formValues.platform
                )}
                onChange={(data) => {
                  setFormValues((prev: any) => ({
                    ...prev,
                    platform: data?.value,
                  }));
                }}
                placeholder="Filter by Platform"
              />
              {!!errors.platform && (
                <Typography variant="subtitle2" mt={1} mx={2} color={"#D32f2f"}>
                  {errors.platform}
                </Typography>
              )}
            </FormControl>
          </Box>

          {formValues?.platform === "Offline" && (
            <Box my={2}>
              <AddressInput
                address={formValues.address as any}
                setAddress={setFormValues as any}
              />
              {!!errors.address && (
                <Typography variant="subtitle2" mt={1} mx={2} color={"#D32f2f"}>
                  {errors.address}
                </Typography>
              )}
            </Box>
          )}
          <TextField
            name="artist"
            margin="dense"
            label="Artist"
            fullWidth
            value={formValues.artist}
            onChange={handleInputChange}
            error={!!errors.artist}
            helperText={errors.artist}
          />
          {formValues.platform === "offline" && (
            <Box my={2}>
              <AddressInput
                address={formValues.address as any}
                setAddress={setFormValues as any}
              />
              {!!errors.address && (
                <Typography variant="subtitle2" mt={1} mx={2} color={"#D32f2f"}>
                  {errors.address}
                </Typography>
              )}
            </Box>
          )}
          <TextField
            name="eventBy"
            margin="dense"
            label="Event By"
            fullWidth
            value={formValues.eventBy}
            onChange={handleInputChange}
            error={!!errors.eventBy}
            helperText={errors.eventBy}
          />
          <TextField
            name="bookingPartner"
            margin="dense"
            label="Booking Partner"
            fullWidth
            value={formValues.bookingPartner}
            onChange={handleInputChange}
            error={!!errors.bookingPartner}
            helperText={errors.bookingPartner}
          />
          <Box marginTop={2}>
            {previewImage ? (
              <Box
                sx={{
                  height: "200px",
                  borderRadius: 2,

                  overflow: "hidden",
                  position: "relative",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    border: "2px dashed #00C5B9",
                    width: "200px",
                  }}
                >
                  <img
                    src={previewImage}
                    alt="event"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box ml={2}>
                  <CustomButton
                    variant="outlined"
                    color="secondary"
                    onClick={handleImageRemove}
                  >
                    Remove
                  </CustomButton>
                </Box>
              </Box>
            ) : (
              <label>
                <input
                  ref={fileInputRef}
                  id="eventImage"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
                <CustomButton
                  variant="outlined"
                  type="button"
                  size="small"
                  onClick={() => fileInputRef?.current?.click()}
                >
                  Upload Image
                </CustomButton>
                {!!errors.image && (
                  <Typography
                    variant="subtitle2"
                    mt={1}
                    mx={2}
                    color={"#D32f2f"}
                  >
                    {errors.image}
                  </Typography>
                )}
              </label>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Box>
            <CustomButton onClick={handleClose} variant="outlined">
              Cancel
            </CustomButton>
          </Box>
          <Box>
            <CustomButton onClick={handleSubmit}>
              {events ? "Update" : "Create"}
            </CustomButton>
          </Box>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default EventsModal;
