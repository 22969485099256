export const ROUTES = {
  HOME: "/home",
  LOGIN: "/login",
  USERS: "/users",
  USERS_DETAILS: "/users/:id",
  PINCODES: "/pincode",
  DEFAULT: "/",
  EVENTS: "/events",
  EVENTS_DETAILS: "/events/:id",
  NEWS: "/news",
  NEWS_DETAILS: "/news/:id",
  BUSINESS: "/bussiness",
  BUSSINESS_DETAILS: "/bussiness/:id",
  DEALS: "/deals",
  DEALS_DETAILS: "/deals/:id",
  CATEGORY: "/category",
  CATEGORY_DETAILS: "/category/:id",
  SUB_CATEGORY: "/subcategory/:id",
  SUB_CATEGORY_DETAILS: "/subcategory/:id",
  BLOGS: "/blogs",
  BLOGS_DETAILS: "/blogs/:id",
  FAQ: "/faqs",
  ADVERTISE: "/advertise",
  CONTACT_US: "/contact-us",
  TAGS: "/tags",
  REVIEW: "/review",
  POPULAR_LOCATION: "/popular-location",
  CATEGORY_LIST: "/category/:id",
  HOME_PAGE_SETTINGS: "/homepagesettings",
  TRACKING_DATA: "/tracking-data",
  Region: "/region",
};
