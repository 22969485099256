// TopBusinessesTab.tsx
import React from "react";
import TopBusinessesTable from "./TopBusinesses";
import { RegionData } from "./types";

interface TopBusinessesTabProps {
  data: any;
  refetch: () => void;
}

const TopBusinessesTab: React.FC<TopBusinessesTabProps> = ({
  data,
  refetch,
}) => (
  <div>
    <TopBusinessesTable topBusinesses={data} reload={refetch} />
  </div>
);

export default TopBusinessesTab;
