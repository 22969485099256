import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  FormControl,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { CREATE_USER, UPDATE_USER } from "src/graphql/mutations";
import Select from "react-select";
import { toast } from "react-toastify";
import { userRoleOptions } from "./User";
import PincodeSelectDropDown from "src/components/Common/PincodeSelectDropDown";
import { validationSchema, UserModalProps } from "./User";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CustomButton from "src/components/CustomButton";

const UserModal: React.FC<UserModalProps> = ({
  open,
  handleClose,
  user,
  refetchUsers,
}) => {
  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);

  const isUpdate = !!user;

  const initialValues = {
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    mobileNumber: user?.mobileNumber || "",
    profession: user?.profession || "",
    pincode: user?.pinCode || null,
    password: "",
    role: user?.role || "USER",
  };

  const handleSubmit = async (
    values: typeof initialValues,
    { setErrors }: any
  ) => {
    try {
      const inputData = {
        firstName: values.firstName,
        lastName: values.lastName,
        mobileNumber: values.mobileNumber,
        profession: values.profession,
        pinCode: values.pincode,
      };

      if (isUpdate) {
        const res = await updateUser({
          variables: {
            _id: user._id,
            input: inputData,
          },
        });
        if (res?.data?.updateUser?.statusCode === 200) {
          toast.success(
            res?.data?.updateUser?.message || "User updated successfully"
          );
        } else {
          throw new Error(res?.errors as any);
        }
      } else {
        const res = await createUser({
          variables: {
            input: {
              ...inputData,
              email: values.email,
              role: values.role,
              password: values.password,
            },
          },
        });
        if (res?.data?.CreateAdmin?.statusCode === 200) {
          toast.success(
            res?.data?.CreateAdmin?.message || "User created successfully"
          );
        } else {
          throw new Error(res?.errors as any);
        }
      }

      handleClose();
      refetchUsers();
    } catch (err: any) {
      if (err.inner) {
        const validationErrors: Record<string, string> = {};
        err.inner.forEach((error: any) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      } else {
        toast.error(
          err.message || "An error occurred while submitting the form."
        );
      }
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle>{isUpdate ? "Update User" : "Create User"}</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(isUpdate)}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form>
            <DialogContent>
              <Field
                name="firstName"
                as={TextField}
                margin="dense"
                label="First Name"
                fullWidth
                error={!!errors.firstName && touched.firstName}
                helperText={<ErrorMessage name="firstName" />}
              />

              <Field
                name="lastName"
                as={TextField}
                margin="dense"
                label="Last Name"
                fullWidth
                error={!!errors.lastName && touched.lastName}
                helperText={<ErrorMessage name="lastName" />}
              />

              {!isUpdate && (
                <Field
                  name="email"
                  as={TextField}
                  margin="dense"
                  label="Email"
                  fullWidth
                  error={!!errors.email && touched.email}
                  helperText={<ErrorMessage name="email" />}
                />
              )}

              <Box my={1}>
                <PincodeSelectDropDown
                  handlePincodeChange={(data: any) =>
                    setFieldValue("pincode", data?.value)
                  }
                  selectedPincode={values.pincode}
                />
              </Box>

              <Field
                name="mobileNumber"
                as={TextField}
                margin="dense"
                label="Mobile Number"
                fullWidth
                error={!!errors.mobileNumber && touched.mobileNumber}
                helperText={<ErrorMessage name="mobileNumber" />}
              />

              <Field
                name="profession"
                as={TextField}
                margin="dense"
                label="Profession"
                fullWidth
                error={!!errors.profession && touched.profession}
                helperText={<ErrorMessage name="profession" />}
              />

              {!isUpdate && (
                <FormControl
                  fullWidth
                  sx={{ zIndex: "9999" }}
                  style={{ marginTop: "12px", marginBottom: "8px" }}
                >
                  <Select
                    value={userRoleOptions.find(
                      (option) => option.value === values.role
                    )}
                    onChange={(option) =>
                      setFieldValue("role", option ? option.value : "USER")
                    }
                    options={userRoleOptions}
                    maxMenuHeight={80}
                    isClearable={true}
                    placeholder="Select User Role"
                  />
                </FormControl>
              )}

              {!isUpdate && (
                <Field
                  name="password"
                  as={TextField}
                  margin="dense"
                  label="Password"
                  fullWidth
                  type="password"
                  error={!!errors.password && touched.password}
                  helperText={<ErrorMessage name="password" />}
                />
              )}
            </DialogContent>
            <DialogActions>
              <CustomButton variant="outlined" onClick={() => handleClose()}>
                Cancel
              </CustomButton>
              <CustomButton type="submit" variant="contained">
                {isUpdate ? "Update" : "Create"}
              </CustomButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UserModal;
