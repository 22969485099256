import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import { LocationOn, Event, MonetizationOn, Group } from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { EventPageProps } from "./Events";
import backButton from "../../Images/backButton.svg";

const EventPage: React.FC<EventPageProps> = ({ data, onClose }) => {
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={backButton}
          alt="backButton"
          height={25}
          width={25}
          style={{ marginRight: "12px", cursor: "pointer" }}
          onClick={onClose}
        />
        <Typography variant="h4" style={{ color: "#00C5B9" }}>
          {" "}
          Events
        </Typography>
      </div>
      <Container maxWidth="md" sx={{ textAlign: "start", overflowY: "hidden" }}>
        <Box sx={{ my: 4 }}>
          <Paper elevation={3} sx={{ borderRadius: 2 }}>
            <img
              src={data?.image || "https://via.placeholder.com/1200x500"}
              alt={`${data?.title} event`}
              style={{
                width: "100%",
                height: "auto",
                borderRadius: "8px 8px 0 0",
              }}
            />
            <Box sx={{ p: 4 }}>
              <Typography variant="h4" component="h1" gutterBottom>
                {data?.title}
              </Typography>
              <Box sx={{ my: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Event Details
                </Typography>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start",
                    textAlign: "start",
                    flexDirection: "column",
                  }}
                >
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" alignItems="center">
                      <LocationOn color="primary" />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {data?.venue}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" alignItems="center">
                      <Event color="primary" />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {data?.startDate}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" alignItems="center">
                      <MonetizationOn color="primary" />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {data?.price} Onwards
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Box display="flex" alignItems="center">
                      <Group color="primary" />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        {data?.eventBy}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="h6" gutterBottom>
                  About Event
                </Typography>
                <Typography variant="body1" paragraph>
                  {data?.description}
                </Typography>
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography variant="h6" gutterBottom>
                  How To Get Here
                </Typography>
                <Typography variant="body1" paragraph>
                  Venue: {data?.venue}
                  <br />
                  Address: {data?.venue}
                  <br />
                  Landmark: {data?.venue}
                  <br />
                  Pincode: 395006
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
                <IconButton href="#" aria-label="Facebook">
                  <FacebookIcon />
                </IconButton>
                <IconButton href="#" aria-label="Instagram">
                  <InstagramIcon />
                </IconButton>
                <IconButton href="#" aria-label="LinkedIn">
                  <LinkedInIcon />
                </IconButton>
                <IconButton href="#" aria-label="Twitter">
                  <TwitterIcon />
                </IconButton>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </>
  );
};

export default EventPage;
