import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Grid,
  Typography,
  IconButton,
  Checkbox,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import {
  CREATE_POPULAR_LOCATIONS,
  UPDATE_POPULAR_LOCATIONS,
} from "src/graphql/mutations";
import PincodeSelectDropDown from "src/components/Common/PincodeSelectDropDown";
import TimingSelect from "src/components/Common/TimingSelect";
import { toast } from "react-toastify";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import CustomButton from "src/components/CustomButton";
import { popularLocationCategoryOptions } from "./popularLocationCategory";
import { useFormik } from "formik";
import * as Yup from "yup";
import RichTextEditor from "src/components/Common/TextEditor";
import { uploadImages } from "src/components/Common/Utils";
interface PopularLocationsModalProps {
  open: boolean;
  handleClose: () => void;
  popularlocations?: any;
  refetchPopularlocations: () => void;
}

interface Image {
  imageUrl: string;
  file?: File;
}

interface Timing {
  day: string;
  startTime: string;
  endTime: string;
  other: string;
}

const PopularLocationsModal: React.FC<PopularLocationsModalProps> = ({
  open,
  handleClose,
  popularlocations,
  refetchPopularlocations,
}) => {
  const [images, setImages] = useState<Image[]>([]);
  const [popularLocationData, setPopularLocationData] = useState<any>();
  const [timings, setTimings] = useState<Timing[]>([]);
  const [errors, setErrors] = useState<any>({});
  const [applySameTime, setApplySameTime] = useState<boolean>(false);
  const [AddPopularLocation] = useMutation(CREATE_POPULAR_LOCATIONS);
  const [updatePopularlocations] = useMutation(UPDATE_POPULAR_LOCATIONS);
  const [draggingIndex, setDraggingIndex] = useState<any>(null);

  // Handle drag start
  const handleDragStart = (index: any) => {
    setDraggingIndex(index);
  };

  // Handle drag over
  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const handleDrop = (index: number) => {
    if (draggingIndex === null) return;

    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(draggingIndex, 1);
    updatedImages.splice(index, 0, movedImage);
    // Revoke old URLs to avoid memory leaks
    updatedImages.forEach((image) => {
      if (image.imageUrl) {
        URL.revokeObjectURL(image.imageUrl);
      }
    });
    setImages(updatedImages);

    setDraggingIndex(null);
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    about: Yup.string().required("Description is required"),
    address: Yup.string().required("Address is required"),
    pinCode: Yup.string().required("Pin Code is required"),
    google_location_link: Yup.string().required("Location is required"),
    UserName: Yup.string().required("Unique name required"),
    // timings: Yup.object().required("Timings name required"),
  });

  const formik = useFormik({
    initialValues: {
      title: popularlocations?.title || "",
      address: popularlocations?.address || "",
      pinCode: popularlocations?.pincodeDetails?._id || "",
      google_location_link: popularlocations?.google_location_link || "",
      category: popularlocations?.category || "All",
      about: popularlocations?.about || "",
      metaTitle: popularlocations?.metaTitle || "",
      metaDescription: popularlocations?.metaDescription || "",
      metaKeywords: popularlocations?.metaKeywords || "",
      UserName: popularlocations?.UserName || "",
    },

    enableReinitialize: true,
    onSubmit: async () => handleSubmit,
  });

  useEffect(() => {
    if (popularlocations) {
      setImages(
        popularlocations.images?.map((img: string) => ({ imageUrl: img })) || []
      );
      setTimings(
        popularlocations.timings?.map(
          ({ __typename, ...timing }: any) => timing
        ) || []
      );
    } else {
      formik.resetForm();
      setImages([{ imageUrl: "" }, { imageUrl: "" }, { imageUrl: "" }]);
      setTimings([{ day: "", startTime: "", endTime: "", other: "" }]);
    }
  }, [popularlocations]);

  const handleImageChange = (index: number, file: File | null) => {
    const updatedImages = [...images];
    if (file) {
      updatedImages[index] = { imageUrl: URL.createObjectURL(file), file };
    } else {
      updatedImages[index] = { imageUrl: "" };
    }
    setImages(updatedImages);
  };

  const handleRemoveImage = (index: number) => {
    const updatedImages = [...images];
    const removedImage = updatedImages[index];
    if (removedImage.imageUrl.startsWith("blob:")) {
      URL.revokeObjectURL(removedImage.imageUrl);
    }
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const addNewImageField = () => {
    setImages([...images, { imageUrl: "" }]);
  };

  const handleTimingChange = (
    index: number,
    field: keyof Timing,
    value: string
  ) => {
    const updatedTimings = [...timings];
    updatedTimings[index] = { ...updatedTimings[index], [field]: value };
    setTimings(updatedTimings);

    // Apply the same time to all days if the checkbox is checked
    if (
      applySameTime &&
      (field === "startTime" || field === "endTime" || field === "other")
    ) {
      const firstTiming = updatedTimings[0];
      const newTimings = updatedTimings.map((timing, idx) => ({
        ...timing,
        startTime: firstTiming.startTime,
        endTime: firstTiming.endTime,
        other: firstTiming.other,
      }));
      setTimings(newTimings);
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApplySameTime(e.target.checked);

    if (e.target.checked) {
      // Apply the first timing to all days
      const firstTiming = timings[0];
      const newTimings = Array.from({ length: 7 }, (_, idx) => ({
        day: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ][idx],
        startTime: firstTiming.startTime,
        endTime: firstTiming.endTime,
        other: firstTiming.other,
      }));
      setTimings(newTimings);
    }
  };

  const addNewTimingField = () => {
    if (timings.length < 7) {
      setTimings([
        ...timings,
        { day: "", startTime: "", endTime: "", other: "" },
      ]);
    }
  };

  const removeTimingField = (index: number) => {
    const updatedTimings = timings.filter((_, i) => i !== index);
    setTimings(updatedTimings);
  };

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formik.values, { abortEarly: false });
      const filesToUpload = images
        .filter((image) => image.file)
        .map((image) => image.file);

      // Use the modified upload function to upload all files in one go
      const uploadedImages = filesToUpload.length
        ? await uploadImages(filesToUpload as File[])
        : [];

      const uploadedImageUrls = uploadedImages.map((img: any) => img.url);
      const existingImageUrls = images
        .filter((image) => !image.file)
        .map((image) => image.imageUrl);

      // Combine existing and uploaded image URLs
      const updatedImages = [...existingImageUrls, ...uploadedImageUrls];
      const variables = {
        input: {
          ...formik?.values,
          images: updatedImages,
          timings: timings,
        },
      };

      const res = popularlocations
        ? await updatePopularlocations({
            variables: { _id: popularlocations._id, input: variables.input },
          })
        : await AddPopularLocation({ variables });

      if (res?.data) {
        handleClose();
        toast.success(
          res?.data?.createPopularLocations?.message ||
            res?.data?.updatePopularLocations?.message ||
            "Location updated successfully!"
        );
        refetchPopularlocations();
      }
    } catch (error: any) {
      if (error.inner) {
        const validationErrors: Record<string, string> = {};
        error.inner.forEach((error: any) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      } else {
        toast.error(error?.message || "Error submitting data.");
      }
    }
  };

  const customHandleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.handleChange(e);
    const { name, value } = e.target;
    const transformedValue =
      name === "UserName" ? value.toLowerCase().replace(/\s+/g, "_") : value;

    if (name === "title") {
      formik.setFieldValue(
        "UserName",
        value.toLowerCase().replace(/\s+/g, "_")
      );
    }
    formik.setFieldValue(name, transformedValue);
    setPopularLocationData((prev: any) => ({
      ...prev,
      [name]: transformedValue,
    }));
  };

  const handleSelectForAll = (index: number) => {
    const selectedTiming = timings[index];
    setTimings(
      timings.map((timing) => ({
        ...timing,
        startTime: selectedTiming.startTime,
        endTime: selectedTiming.endTime,
        other: selectedTiming.other,
      }))
    );
  };

  const usedDays = timings.map((timing) => timing.day);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      sx={{ marginTop: "40px" }}
    >
      <DialogTitle style={{ color: "#00C5B9" }}>
        {popularlocations
          ? "Update Popular Location"
          : "Create Popular Location"}
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent dividers>
          <Box mb={3}>
            <TextField
              label="Title"
              name="title"
              value={formik.values.title}
              onChange={customHandleChange}
              fullWidth
              variant="outlined"
              error={!!errors.title}
              helperText={errors.title}
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Unique Name"
              name="UserName"
              value={formik.values.UserName}
              onChange={customHandleChange}
              fullWidth
              variant="outlined"
              error={!!errors.UserName}
              helperText={errors.UserName}
            />
          </Box>
          <Box mb={3}>
            <Typography variant="subtitle1" mx={1}>
              About{" "}
            </Typography>
            <RichTextEditor
              value={popularLocationData?.about || formik?.values?.about}
              onChange={(value) => {
                formik.setValues((prev: any) => ({
                  ...prev,
                  ["about"]: value,
                }));
                setPopularLocationData((prevBlog: any) => ({
                  ...prevBlog,
                  ["about"]: value,
                }));
              }}
            />
            {errors.about && (
              <Typography variant="caption" color="error" mx={1.5}>
                {errors.about}
              </Typography>
            )}
          </Box>
          <Box mb={3}>
            <TextField
              label="Address"
              name="address"
              value={formik.values.address}
              onChange={customHandleChange}
              fullWidth
              variant="outlined"
              error={!!errors.address}
              helperText={errors.address}
            />
          </Box>
          <Box mb={3}>
            <PincodeSelectDropDown
              selectedPincode={formik.values.pinCode}
              handlePincodeChange={(data: any) => {
                formik.setValues((prev: any) => ({
                  ...prev,
                  ["pinCode"]: data?.value,
                }));
                setPopularLocationData((prev: any) => ({
                  ...prev,
                  ["pinCode"]: data?.value,
                }));
              }}
            />
            {errors.pinCode && (
              <Typography variant="caption" color="error" mx={1.5}>
                {errors.pinCode}
              </Typography>
            )}
          </Box>
          <Box mb={3}>
            <TextField
              label="Google Location Link"
              name="google_location_link"
              value={formik.values.google_location_link}
              onChange={customHandleChange}
              fullWidth
              variant="outlined"
              error={!!errors.google_location_link}
              helperText={errors.google_location_link}
            />
          </Box>
          <Box mb={3}>
            <Select
              options={popularLocationCategoryOptions}
              placeholder="Select Category"
              value={{
                value: popularLocationData?.category || formik.values.category,
                label: popularLocationData?.category || formik.values.category,
              }}
              onChange={(selected) => {
                formik.setValues((prev: any) => ({
                  ...prev,
                  ["category"]: selected?.value,
                }));
                setPopularLocationData((prev: any) => ({
                  ...prev,
                  ["category"]: selected?.value,
                }));
              }}
              isClearable
            />
          </Box>
          <Box mb={3}>
            <Grid container spacing={2}>
              {images.map((image, index) => (
                <Grid
                  item
                  xs={4}
                  key={index}
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  onDragOver={handleDragOver}
                  onDrop={() => handleDrop(index)}
                >
                  <Box position="relative">
                    <img
                      src={
                        image.imageUrl ||
                        "https://via.placeholder.com/150x150?text=Add+Image"
                      }
                      alt={`Image ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                    <IconButton
                      size="small"
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                      }}
                      onClick={() => handleRemoveImage(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                    <CustomButton
                      variant="contained"
                      component="label"
                      style={{ marginTop: "10px", width: "100%" }}
                    >
                      {image.imageUrl ? "Change Image" : "Add Image"}
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={(e) =>
                          handleImageChange(
                            index,
                            e.target.files ? e.target.files[0] : null
                          )
                        }
                      />
                    </CustomButton>
                  </Box>
                </Grid>
              ))}
            </Grid>
            <Box mt={2}>
              <CustomButton onClick={addNewImageField} variant="outlined">
                Add New Image
              </CustomButton>
            </Box>
          </Box>
          {/* Timings Management */}
          <Box sx={{ marginTop: "20px" }}>
            <Typography variant="subtitle1">Timings:</Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <Checkbox
                checked={applySameTime}
                onChange={handleCheckboxChange}
                inputProps={{ "aria-label": "Apply same time to all days" }}
              />
              <Typography variant="body2" ml={1}>
                Apply same timing for all days
              </Typography>
            </Box>

            {timings.map((timing, index) => (
              <Box key={index} mt={2}>
                <TimingSelect
                  key={index}
                  timings={timings}
                  onTimingChange={handleTimingChange}
                  onRemove={removeTimingField}
                  availableDays={usedDays}
                  index={index}
                />
                <Box sx={{ width: "300px" }} my={1}>
                  <CustomButton
                    variant="outlined"
                    onClick={() => handleSelectForAll(index)}
                  >
                    Apply Timing to All Days
                  </CustomButton>
                </Box>
                {errors.timings && (
                  <Typography variant="caption" color="error" mx={1.5}>
                    {errors.timings}
                  </Typography>
                )}
              </Box>
            ))}
            <Box my={2}>
              <CustomButton onClick={addNewTimingField}>
                Add Timing
              </CustomButton>
            </Box>
          </Box>
          <Box mb={3}>
            <TextField
              label="Meta Title"
              name="metaTitle"
              value={formik?.values?.metaTitle}
              onChange={customHandleChange}
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Meta Description"
              name="metaDescription"
              value={formik?.values?.metaDescription}
              onChange={customHandleChange}
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box mb={3}>
            <TextField
              label="Meta Keywords"
              name="metaKeywords"
              value={formik?.values?.metaKeywords}
              onChange={customHandleChange}
              fullWidth
              variant="outlined"
            />
          </Box>
        </DialogContent>
      </form>
      <DialogActions>
        <Box>
          <CustomButton variant="outlined" onClick={handleClose}>
            Cancel
          </CustomButton>
        </Box>
        <Box>
          <CustomButton
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            type="submit"
          >
            {popularlocations ? "Update" : "Create"}
          </CustomButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PopularLocationsModal;
