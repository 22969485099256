// utils/apolloClient.js
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { localStorageGetItem } from "./utils/localStorage";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT, // Replace with your GraphQL endpoint
});

const authLink = setContext((_, { headers }) => {
  // Check if running in the browser environment
  if (typeof window !== "undefined") {
    // Get the authentication token from local storage if it exists
    const token = localStorageGetItem("accessToken");
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  } else {
    return { headers };
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
