import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_BUSINESS, VERIFY_BUSINESS } from "src/graphql/mutations";
import { GET_BUSINESS, GET_BUSINESS_PROFILE } from "src/graphql/query";
import { debounce } from "src/utils/debounce";
import CustomTable from "src/components/CustomTable";
import Loader from "src/components/Loader";
import ErrorComponent from "src/components/ErrorComponent";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomButton from "src/components/CustomButton";
import BusinessModal from "./BusinessModal";
import { toast } from "react-toastify";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BusinessProfiles from "./BusinessProfile";
import VerifyEventsDialog from "../Events/VerifyEvents";
import DeleteModel from "src/components/Common/DeleteModel";

interface Business {
  _id: string;
  businessName: string;
  mobileNumber: string;
  status: string;
  verifiedBy: string;
}

const Business: React.FC = () => {
  const [list, setList] = useState<Business[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(
    null
  );
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [total, setTotal] = useState(0);
  const [deleteBusiness] = useMutation(DELETE_BUSINESS);
  const [verifyBusinessProfile] = useMutation(VERIFY_BUSINESS);
  const [verifyBusinessProfileValue, setVerifyBusinessProfileValue] =
    useState<string>("");
  const [verifyingBusiness, setVerifyingBusiness] = useState<string | null>(
    null
  );
  const [isBusinessOpen, setIsBusinessPageOpen] = useState<boolean>(false);
  const [businessPageData, setBusinessPageData] = useState<any>();
  const [openDeleteModel, setOpenDeleteModel] = useState<boolean>(false);
  const [deleteBusinessId, setDeleteBusinessId] = useState<string | null>(null);

  const COLUMNS = [
    { headerName: "Business Name", field: "businessName", flex: 1 },
    { headerName: "Mobile Number", field: "mobileNumber", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    { headerName: "Verified By", field: "verifiedBy", flex: 1 },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: ({ row }: { row: Business }) => (
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => handleView(row)}
            aria-label="view"
            style={{ color: "#00C5B9" }}
          >
            <RemoveRedEyeIcon />
          </IconButton>
          <Tooltip title="Edit">
            <IconButton
              onClick={() => handleEdit(row)}
              aria-label="edit"
              style={{ marginRight: "8px", color: "#00C5B9" }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={() => {
                setDeleteBusinessId(row._id);
                setOpenDeleteModel(true);
              }}
              aria-label="delete"
              style={{ marginRight: "8px", color: "#00C5B9" }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Verify Business">
            <CustomButton
              variant="outlined"
              color="primary"
              style={{ marginLeft: 2 }}
              onClick={() => setVerifyingBusiness(row._id)}
            >
              Verify
            </CustomButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const [reason, setReason] = useState<string>("");
  const { loading, error, data, refetch } = useQuery(GET_BUSINESS, {
    variables: {
      page: pagination.page + 1,
      limit: pagination.pageSize,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { refetch: refetchBusinessProfile } = useQuery(GET_BUSINESS_PROFILE, {
    skip: true,
  });

  useEffect(() => {
    if (data?.getBusinessProfilesAll?.data) {
      setList(data.getBusinessProfilesAll.data);
      setTotal(data.getBusinessProfilesAll.count);
    }
  }, [data]);

  const handleView = async (rowData: any) => {
    setIsBusinessPageOpen(true);
    try {
      const res = await refetchBusinessProfile({ _id: rowData?._id });
      setBusinessPageData(res?.data?.getBusinessProfile?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedRefetch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
      refetch({
        search: term,
        page: pagination.page + 1,
        limit: pagination.pageSize,
      });
    }, 300),
    [refetch, pagination.page, pagination.pageSize]
  );

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
    refetch({
      search: searchTerm,
      page: newPage + 1,
      limit: pagination.pageSize,
    });
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPagination((prev) => ({ ...prev, pageSize: newPageSize }));
    refetch({
      search: searchTerm,
      page: pagination.page + 1,
      limit: newPageSize,
    });
  };

  const handleEdit = async (rowData: Business) => {
    const { data: businessData } = await refetchBusinessProfile({
      _id: rowData?._id,
    });
    setSelectedBusiness(businessData?.getBusinessProfile?.data);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    if (!deleteBusinessId) return;
    try {
      await deleteBusiness({
        variables: { id: deleteBusinessId },
      });
      refetch();
      setOpenDeleteModel(false);
    } catch (error) {
      console.error("Error deleting business:", error);
    }
  };

  const handleVerify = async (businessId: string, status: string) => {
    try {
      const res = await verifyBusinessProfile({
        variables: {
          input: {
            _id: businessId,
            status,
            reason,
          },
        },
      });

      if (res.errors) {
        throw new Error(
          `GraphQL error! message: ${res.errors
            .map((error) => error.message)
            .join(", ")}`
        );
      }

      refetch();
      toast.success(res?.data?.verifyBusinessProfile?.message);
      setVerifyingBusiness(null);
    } catch (error) {
      console.error("Error verifying business:", error);
    }
  };
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedRefetch(value);
  };

  const handleCloseBusinessPage = () => {
    setIsBusinessPageOpen(false);
  };

  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
    setDeleteBusinessId(null);
  };

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorComponent />;
  }

  return (
    <>
      {openModal ? (
        <BusinessModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            setSelectedBusiness(null);
          }}
          business={selectedBusiness}
          refetch={refetch}
        />
      ) : (
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h4" style={{ color: "#00C5B9" }}>
              Business
            </Typography>
            <Box display="flex" alignItems="center" gap={2}>
              <Box width={200}>
                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </Box>
              <Box width={100}>
                <CustomButton
                  onClick={() => setOpenModal(true)}
                  variant="contained"
                >
                  Create
                </CustomButton>
              </Box>
            </Box>
          </Box>
          <Divider />
          <CustomTable
            columns={COLUMNS}
            data={list}
            paginationModel={{
              page: Number(pagination.page),
              pageSize: Number(pagination.pageSize),
            }}
            onPageSizeChange={handlePageSizeChange}
            onPageChange={handlePageChange}
            totalCount={total}
          />
        </Box>
      )}

      {isBusinessOpen && businessPageData && (
        <Dialog
          open={isBusinessOpen}
          onClose={handleCloseBusinessPage}
          maxWidth="md"
          fullWidth
          style={{ marginTop: "40px" }}
        >
          <DialogTitle>Business Profile</DialogTitle>
          <DialogContent>
            <BusinessProfiles businessProfile={businessPageData} />
          </DialogContent>
          <DialogActions>
            <Box>
              <CustomButton
                onClick={handleCloseBusinessPage}
                color="primary"
                variant="outlined"
              >
                Close
              </CustomButton>
            </Box>
          </DialogActions>
        </Dialog>
      )}

      <VerifyEventsDialog
        handleVerify={handleVerify}
        setVerifyingData={setVerifyingBusiness}
        verifyingData={verifyingBusiness}
        verifyDataValue={verifyBusinessProfileValue}
        reason={reason}
        setVerifyDataValue={setVerifyBusinessProfileValue}
        setReason={setReason}
      />

      {openDeleteModel && (
        <DeleteModel
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          onConfirm={handleDelete}
          message="Are you sure you want to delete this business?"
        />
      )}
    </>
  );
};

export default Business;
