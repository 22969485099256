import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  businessName: Yup.string().required("Business Name is required"),
  primarySubCategory: Yup.string().required("Subcategory is required"),
  tag: Yup.string().required("Tag is required"),
  brandName: Yup.string().required("Brand Name is required"),
  brandLogo: Yup.string().required("Brand Logo is required"),
  website: Yup.string().url("Invalid URL"),
  businessEmail: Yup.string()
    .email("Invalid Email")
    .required("Email is required"),
  // documents: Yup.string().required("Documents are required"),
  managedBy: Yup.string().required("Manager Name is required"),
  mobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Must be a valid 10-digit mobile number")
    .required("Mobile Number is required"),
  bgImage: Yup.string().required("Background Image is required"),
  businessType: Yup.string().required("Business Type is required"),
  owners: Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Must be a valid 10-digit mobile number")
      .required("Mobile Number is required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
  }),
  addresses: Yup.array()
    .of(
      Yup.object().shape({
        address: Yup.string().required("Address is required"),
        buildingOrShopNumber: Yup.string().required(
          "Building/Shop Number is required"
        ),
        landmark: Yup.string().required("Landmark is required"),
        neighborhood: Yup.string().required("Neighborhood is required"),
        googleMapLink: Yup.string().url("Invalid URL"),
        pinCode: Yup.string().required("PIN Code is required"),
      })
    )
    .required("Must have at least one address"),
  // gallery: Yup.object().shape({
  //   image: Yup.string().required("Gallery Image is required"),
  // }),
  // timing: Yup.string().required("Timing is required"),
  //   overview: Yup.string().required("Overview is required"),
});

export interface Address {
  address?: string;
  buildingOrShopNumber?: string;
  landmark?: string;
  neighborhood?: string;
  googleMapLink?: string;
  pinCode?: string;
}

export interface FormValues {
  UserName: string;
  businessName: string;
  primarySubCategory: string;
  additionalSubCategories: string[];
  brandName: string;
  brandLogo: string;
  website?: string;
  businessEmail: string;
  documents?: any;
  managedBy: string;
  mobileNumber: string;
  bgImage: string;
  businessType: any;
  owners: {
    firstName: string;
    lastName: string;
    mobileNumber: string;
    email: string;
  };
  addresses: Address[];
  gallery: any;
  timing?: any[];
  overview: any;
}

export const options = [
  { value: "FOREVERYONE", label: "FOR EVERYONE" },
  { value: "FORBUSINESS", label: "FOR BUSINESS" },
];

export interface Option {
  label: string;
  value: string | null;
  id: string;
  isSelect?: boolean;
}

export interface DynamicOption {
  id: string;
  type: "checkbox" | "dropdown" | "radio";
  display: string;
  order: number;
  options: Option[];
  isDynamic: boolean;
  isKey: boolean | null;
  value?: string | null;
}

export interface OverviewData {
  dynamicOptions: DynamicOption[];
}

export interface OverviewComponentProps {
  data: OverviewData;
}

export interface BusinessProfileProps {
  businessProfile: {
    businessName: string;
    brandName: string;
    brandLogo: string;
    bgImage: string;
    category: string;
    subCategory: string;
    mobileNumber: string;
    timings: any;
    address: any;
    overviews: any;
    galleries: { image: string[] }[];
    user: {
      firstName: string;
      lastName: string;
      email?: string;
      role: string;
    };
  };
}
